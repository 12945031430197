import React, { useState, useEffect } from "react";
import { Link } from "react-scroll"; 

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navLinks = [
    { name: "Home", to: "home" },
    { name: "About", to: "about" },
    { name: "Services", to: "services" },
    { name: "Gallery", to: "gallery" },
    { name: "Contact", to: "contact" },
  ];

  return (
    <nav
      className="fixed top-0 w-full z-40 transition-colors duration-300  bg-[#121212]"
        
    >
      <div className="items-center flex justify-between h-20 max-w-[1300px] mx-auto">
        <div className="logo">
          <Link to="home" smooth={true} duration={500}>
            <img src="https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/Torque-Logo1.png" alt="Torque Logo" className="w-24 cursor-pointer" />
          </Link>
        </div>

        <div className="flex items-center">
          {/* Desktop Navigation Links */}
          <div className="hidden md:flex gap-[2em] items-center">
            {navLinks.map((link, index) => (
              <div key={index} className="link">
                <Link
                  to={link.to}
                  smooth={true}
                  duration={500}
                  className="cursor-pointer text-white uppercase font-bold hover:text-[#A0CF3B]"
                >
                  {link.name}
                </Link>
              </div>
            ))}
            {/* <a
              href="https://insider.in/return-of-the-dragon-machi-home-edition-oct19-2024-chennai/event" target="_blank"
              className="bg-[#A0CF3B] text-black uppercase font-bold py-2 px-4 rounded hover:bg-[#8DBA32] transition-colors duration-300"
            >
              Buy Tickets
            </a> */}
          </div>
          <button
            onClick={handleMenuToggle}
            className="block md:hidden text-white focus:outline-none mr-2 rounded"
          >
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="absolute top-full right-0 w-full h-[100vh] text-[#A0CF3B] bg-[#121212] flex flex-col items-center md:hidden">
          {navLinks.map((link, index) => (
            <Link
              key={index}
              to={link.to}
              smooth={true}
              duration={500}
              onClick={() => setIsMenuOpen(false)}
              className="block py-2 cursor-pointer uppercase text-xl font-bold"
            >
              {link.name}
            </Link>
          ))}
          {/* <a
            href="https://insider.in/return-of-the-dragon-machi-home-edition-oct19-2024-chennai/event"
            className="bg-[#A0CF3B] text-black uppercase font-bold py-2 px-4 mt-4 rounded hover:bg-[#8DBA32] transition-colors duration-300"
          >
            Buy Tickets
          </a> */}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
