import React, { useRef, useEffect } from "react";
import { motion, useInView } from "framer-motion";
import { animateContentBlocks } from "../utils/whatwecan-animation";

const WhatweCan = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    animateContentBlocks();
  }, []);

  const ContentBlock = ({ heading, imgSrc, subheading, reverse = false, gradientColors }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: false });

    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 50 },
        }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className={`content-block group relative flex flex-col md:flex-row ${reverse ? "md:flex-row-reverse" : "md:flex-row"}
          items-center justify-between py-0 md:py-8 px-4 md:px-8 -mt-[100px] md:mt-0`}
      >
        <div className="py-2 md:py-8 md:w-[600px] text-center md:text-left">
          <span
            className="relative z-10 mt-4 block text-2xl md:text-4xl font-bold bg-clip-text text-transparent"
            style={{
              backgroundImage: `linear-gradient(to right, ${gradientColors.join(", ")})`,
            }}
          >
            {heading}
          </span>
          <span className="relative z-10 mt-2 block text-lg md:text-xl text-neutral-600 transition-colors duration-300 group-hover:text-neutral-50">
            {subheading}
          </span>
        </div>

        <motion.img
          style={{
            translateX: "10%",
            translateY: "-20%",
            rotate: reverse ? "-12deg" : "12deg",
          }}
          variants={{
            hidden: { scale: 0 },
            visible: { scale: 1 },
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10, duration: 1 }}
          src={imgSrc}
          className="hidden md:block z-0 h-20 w-40 md:h-60 md:w-72 rounded-lg object-cover"
          alt=""
        />
      </motion.div>
    );
  };

  const gradientColorsList = [
    ["#8E44AD", "#F5EEF7"],
    ["#F39C12", "#FDF9E7"],
    ["#4498DB", "#33FFFF"]
  ];

  return (
    <section ref={sectionRef} className="py-12">
      <div className="text-white font-semibold text-center  text-3xl md:text-4xl mb-4">
        <h1 >What <span className=' text-[#A0CF3B]'>We do!</span></h1>
      </div>
      <div className="mx-auto max-w-3xl md:max-w-5xl space-y-12 md:space-y-16 mt-20 md:mt-2">
        <ContentBlock
          heading="We have everything!"
          subheading="Driving the dagger to the perfect centre we ripple through every direction to bring solutions to your event requirements."
          imgSrc="https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/services/Virtual.jpg"
          gradientColors={gradientColorsList[0]}
        />
        <ContentBlock
          heading="We Know Everyone!"
          subheading="Our contact book of event management is pretty huge. We know the market and can make a few calls to get things done right on time."
          imgSrc="https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/services/Hybrid.jpg"
          reverse
          gradientColors={gradientColorsList[1]}
        />
        <ContentBlock
          heading="We create every way!"
          subheading="We own Doremon’s magic pocket so all you gotta do is bring us your objectives and we will bring a lot of breakthrough ideas. Strictly not usual."
          imgSrc="https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/services/Space.jpg"
          gradientColors={gradientColorsList[2]}
        />
      </div>
    </section>
  );
};

export default WhatweCan;
