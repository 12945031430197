import React, { useEffect, useState } from 'react';
import { Link } from "react-scroll";

const Footer = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navLinks = [
    { name: "Home", to: "home" },
    { name: "About", to: "about" },
    { name: "Services", to: "services" },
    { name: "Gallery", to: "gallery" },
    { name: "Contact", to: "contact" },
  ];

  return (
    <footer className="bg-gradient-to-b from-black to-[#A0CF3B]">
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className='w-full flex justify-center items-center'>
          <img src="https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/Torque-Logo1.png" alt="Torque Logo" className='h-24' />
        </div>

        <p className="mx-auto mt-6 max-w-md text-center text-white text-lg">
          Experience the Unexpected with us!
        </p>

        <ul className="mt-6 flex flex-wrap justify-center gap-10 uppercase text-white font-bold md:gap-8 lg:gap-12">
          {navLinks.map((link, index) => (
            <li key={index}>
              <Link 
                to={link.to} 
                smooth={true} 
                duration={500} 
                offset={-50} 
                className="transition hover:text-black cursor-pointer"
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>

        <p className="mx-auto mt-6 max-w-sm text-center">
          © {new Date().getFullYear()} Torque Entertainment. All rights reserved. <br /> 
          Designed by <a href="https://namuvi.com/" className='underline' target='_blank'>Namuvi Technologies</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
