import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const animateContentBlocks = () => {
  const blocks = gsap.utils.toArray(".content-block");

  blocks.forEach((block, i) => {
    gsap.fromTo(
      block,
      {
        opacity: 0,
        y: 50,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: block,
          start: "top 80%", // Adjust start position for better visibility
          end: "bottom 60%",
          toggleActions: "play none none none", // Ensure the animation plays and stays in place
        },
      }
    );
  });
};
