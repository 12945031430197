// src/animations/useAnimations.js
import { gsap } from "gsap";
import { useEffect } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const useServicesAnimations = (titleRef, cardsRef) => {
  useEffect(() => {
    // Create a timeline for sequential animations
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: titleRef.current,
        start: "top 50%", // Trigger when the top of the element hits 50% of the viewport height
        end: "bottom 60%",
        toggleActions: "play none none none",
      },
    });

    // Animate the "Services" title first
    tl.fromTo(
      titleRef.current.querySelector(".services-title"),
      { y: -100, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.5, ease: "power3.out" }
    )
      // Then animate the first line of the heading
      .fromTo(
        titleRef.current.querySelector(".line1"),
        { y: -100, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.6, ease: "power3.out" },
        "+=0.2" // Delay of 0.2 seconds after the previous animation
      )
      // Finally, animate the second line of the heading
      .fromTo(
        titleRef.current.querySelector(".line2"),
        { y: -100, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.7, ease: "power3.out" },
        "+=0.2" // Delay of 0.2 seconds after the previous animation
      );

    // Cards reveal from bottom
    gsap.fromTo(
      cardsRef.current.children,
      { y: 100, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        ease: "power3.out",
        stagger: 0.2,
        scrollTrigger: {
          trigger: cardsRef.current,
          start: "top 80%", // Adjust as needed
          end: "bottom 60%", // Adjust as needed
          toggleActions: "play none none none", // Adjust based on your need
        },
      }
    );
  }, [titleRef, cardsRef]);
};
