import React, { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";

const About = () => {
  const [showContent, setShowContent] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const videoRef = useRef(null);

  const itemsLeft = ["Experienced Team", "Infinite Ideas", "Best moments"];
  const itemsRight = ["Elaborate Detailing", "Structured Events", "Approachable Team"];

  const isInview = useInView(videoRef, { once: true });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    handleResize(); 
    window.addEventListener("resize", handleResize); 

    return () => window.removeEventListener("resize", handleResize); 
  }, []);

  useEffect(() => {
    if (isMobile) {
      setShowContent(true); 
    } else if (isInview) {
      const timer = setTimeout(() => {
        setShowContent(true); 
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isInview, isMobile]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 2,
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { ease: "easeOut", duration: 0.6 } },
  };

  return (
    <section className="relative about">
      {!showContent ? (
        <div ref={videoRef} className="h-screen w-full flex items-center justify-center">
          {!isMobile && isInview && (
            <video
              src="https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/videos/About-sec.mp4"
              autoPlay
              muted
              className="w-full h-full object-cover"
            />
          )}
        </div>
      ) : (
        <motion.div
          className="py-8 md:py-32 -mt-56 md:-mt-0"
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <div className="max-w-[1200px] mx-auto text-white px-4 bg-transparent">
            <motion.div
              className="flex flex-col gap-6 justify-center items-center"
              variants={itemVariants}
            >
              <motion.div className="relative" variants={itemVariants}>
                <h1 className="relative text-3xl md:text-4xl lg:text-5xl text-center font-bold bg-gradient-to-r from-[#A0CF3B] to-white bg-clip-text text-transparent">
                  Events don't <span className="">end</span>, but it <br />
                  ignites a <span className="">purpose</span>.
                </h1>
                <p className="relative text-lg md:text-xl text-center max-w-full md:max-w-4xl mt-4">
                  According to us, every event tells a story and carries a
                  purpose. <br />With decades of experience, we at{" "}
                  <span className="text-[#A0CF3B] font-semibold">
                    Torque Entertainment
                  </span>{" "}
                  ensure you experience the best. <br /> We help your event reach your
                  audience effortlessly while you stay at peace.
                </p>
              </motion.div>

              <motion.div
                className="grid grid-cols-1 md:grid-cols-2 gap-8"
                variants={containerVariants}
              >
                <ul className="flex flex-col gap-3 text-lg md:text-xl">
                  {itemsLeft.map((item, index) => (
                    <motion.li
                      key={index}
                      className="flex items-center"
                      variants={itemVariants}
                    >
                      <img src="https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/splash.png" alt="" className="h-10 mr-2" />
                      {item}
                    </motion.li>
                  ))}
                </ul>
                <ul className="flex flex-col gap-3 text-lg md:text-xl">
                  {itemsRight.map((item, index) => (
                    <motion.li
                      key={index}
                      className="flex items-center"
                      variants={itemVariants}
                    >
                      <img src="https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/splash.png" alt="" className="h-10 mr-2" />
                      {item}
                    </motion.li>
                  ))}
                </ul>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </section>
  );
};

export default About;
