import React from 'react';

const InkSplatter = () => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width="1280.000000pt" height="958.000000pt" viewBox="0 0 1280.000000 958.000000"
     preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,958.000000) scale(0.100000,-0.100000)"
    fill="#A0CF3B" stroke="none">
    <path d="M1793 9569 c14 -8 22 -8 30 0 8 8 3 11 -18 11 -26 -1 -28 -2 -12 -11z"/>
    <path d="M6967 9534 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z"/>
    <path d="M44 9515 c-7 -17 12 -45 31 -45 19 0 36 25 29 44 -8 20 -53 21 -60 1z"/>
    <path d="M5722 9518 c-30 -30 1 -73 36 -51 23 14 25 29 7 47 -20 19 -27 20
    -43 4z"/>
    <path d="M5514 9465 c-4 -9 -2 -21 4 -27 15 -15 44 -1 40 19 -4 23 -36 29 -44
    8z"/>
    <path d="M10617 9343 c-13 -12 -7 -33 8 -33 8 0 15 9 15 20 0 20 -11 26 -23
    13z"/>
    <path d="M5962 9138 c-27 -27 -6 -68 35 -68 31 0 52 42 33 65 -15 18 -51 20
    -68 3z"/>
    <path d="M7365 9141 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
    -9z"/>
    <path d="M7591 9041 c-16 -28 14 -51 36 -28 13 13 14 19 3 31 -17 21 -27 20
    -39 -3z"/>
    <path d="M6326 9041 c-10 -16 5 -42 23 -39 21 4 21 42 -1 46 -9 2 -19 -1 -22
    -7z"/>
    <path d="M9916 9041 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
    <path d="M766 8962 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
    <path d="M3292 8893 c2 -10 10 -18 18 -18 22 0 18 29 -3 33 -14 3 -18 -1 -15
    -15z"/>
    <path d="M292 8888 c-17 -17 -15 -53 3 -68 20 -17 62 -5 70 21 13 40 -44 76
    -73 47z"/>
    <path d="M4140 8840 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M9009 8824 c-17 -21 0 -48 34 -52 18 -2 27 2 32 18 13 41 -38 68 -66
    34z"/>
    <path d="M2660 8819 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
    -5 -10 -11z"/>
    <path d="M3780 8755 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
    -15 -7 -15 -15z"/>
    <path d="M6522 8759 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
    -15 -5 -13 -11z"/>
    <path d="M5853 8633 c-19 -7 -16 -50 4 -57 24 -10 51 20 37 41 -12 19 -23 24
    -41 16z"/>
    <path d="M7157 8524 c-15 -15 -7 -63 13 -74 33 -17 62 4 58 43 -3 27 -7 32
    -33 35 -17 2 -34 0 -38 -4z"/>
    <path d="M6875 8506 c-20 -15 -17 -44 7 -59 12 -8 21 -7 32 2 36 30 -1 85 -39
    57z"/>
    <path d="M8757 8514 c-13 -13 -7 -52 9 -58 22 -9 46 13 42 38 -3 21 -37 34
    -51 20z"/>
    <path d="M7824 8496 c-12 -31 0 -51 31 -51 27 0 30 3 30 30 0 25 -4 30 -28 33
    -17 2 -29 -2 -33 -12z"/>
    <path d="M5127 8474 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z"/>
    <path d="M6545 8444 c-11 -12 -12 -20 -5 -35 12 -22 33 -25 49 -5 25 30 -16
    67 -44 40z"/>
    <path d="M6763 8418 c-48 -53 -55 -103 -23 -156 51 -83 177 -74 211 15 33 87
    -25 163 -124 163 -32 0 -49 -6 -64 -22z"/>
    <path d="M7270 8380 c-11 -11 -20 -27 -20 -35 0 -22 39 -57 57 -51 8 3 18 6
    22 6 16 0 29 46 18 66 -21 39 -47 44 -77 14z"/>
    <path d="M12085 8300 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
    0 -8 -4 -11 -10z"/>
    <path d="M4560 8280 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
    -13 0 -20 -7 -20 -20z"/>
    <path d="M955 8261 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33 14z"/>
    <path d="M10150 8189 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
    -10 -5 -10 -11z"/>
    <path d="M5742 8178 c-18 -18 -14 -46 7 -58 15 -7 23 -6 35 6 20 19 20 29 0
    48 -19 19 -26 20 -42 4z"/>
    <path d="M11062 8148 c-16 -16 -15 -38 1 -38 19 0 36 20 30 36 -6 17 -15 18
    -31 2z"/>
    <path d="M9770 8119 c0 -25 33 -22 38 4 2 12 -3 17 -17 17 -15 0 -21 -6 -21
    -21z"/>
    <path d="M5233 8103 c-3 -10 -8 -21 -10 -25 -2 -5 0 -8 5 -8 5 0 14 11 20 25
    7 16 8 25 1 25 -5 0 -12 -8 -16 -17z"/>
    <path d="M4080 8038 c0 -6 11 -30 25 -54 14 -24 22 -45 18 -47 -21 -13 -74
    -17 -91 -8 -13 7 -22 7 -31 -2 -9 -9 -16 -9 -31 1 -23 14 -40 16 -40 3 0 -5
    14 -14 31 -20 17 -6 29 -16 27 -23 -7 -20 -50 -38 -83 -36 -24 3 -31 8 -35 32
    -3 16 -11 31 -17 34 -22 7 -14 -29 17 -75 28 -41 29 -46 15 -66 -22 -31 -19
    -58 10 -91 14 -16 25 -33 25 -37 0 -24 152 -70 164 -50 4 6 26 11 49 11 23 0
    55 7 70 15 25 13 28 13 33 -3 15 -49 47 -102 70 -117 20 -13 23 -19 14 -31 -7
    -7 -10 -21 -6 -29 8 -21 37 -19 43 4 7 28 81 35 106 9 45 -46 114 14 79 70 -5
    7 -4 34 1 61 13 59 -1 98 -47 139 -27 24 -32 34 -28 63 3 28 -2 39 -30 67 -29
    27 -39 31 -65 26 -47 -9 -75 -45 -75 -94 1 -24 -3 -38 -9 -34 -6 3 -9 18 -7
    33 1 15 -7 40 -19 56 -11 17 -22 39 -24 50 -3 16 -11 19 -35 17 -35 -4 -50 11
    -89 86 -27 53 -35 61 -35 40z"/>
    <path d="M5575 7990 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
    <path d="M2335 7940 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
    7z"/>
    <path d="M5290 7875 c-9 -11 -10 -19 -3 -26 17 -17 48 -6 48 16 0 25 -28 31
    -45 10z"/>
    <path d="M7534 7866 c-9 -24 4 -48 23 -44 12 2 18 12 18 28 0 29 -32 41 -41
    16z"/>
    <path d="M8185 7810 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z"/>
    <path d="M9731 7740 c-19 -11 -6 -41 14 -34 16 6 20 29 7 38 -4 2 -14 1 -21
    -4z"/>
    <path d="M11180 7730 c0 -16 5 -21 23 -18 12 2 22 10 22 18 0 8 -10 16 -22 18
    -18 3 -23 -2 -23 -18z"/>
    <path d="M5530 7660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M5107 7633 c-13 -12 -7 -23 13 -23 11 0 20 7 20 15 0 15 -21 21 -33
    8z"/>
    <path d="M6571 7596 c-16 -20 -5 -53 20 -61 27 -9 51 13 47 44 -2 20 -9 27
    -29 29 -14 2 -31 -4 -38 -12z"/>
    <path d="M4090 7583 c-9 -2 -28 -23 -44 -46 -22 -34 -26 -49 -22 -79 9 -53 40
    -101 68 -103 13 -2 37 -8 55 -16 28 -11 37 -11 67 4 67 31 89 104 52 175 -27
    53 -110 83 -176 65z"/>
    <path d="M1364 7565 c-4 -9 -2 -21 4 -27 15 -15 44 -1 40 19 -4 23 -36 29 -44
    8z"/>
    <path d="M4879 7523 c-24 -65 -49 -143 -49 -158 0 -21 17 -19 29 3 9 17 51
    163 51 177 0 17 -22 1 -31 -22z"/>
    <path d="M45 7526 c-12 -9 -15 -19 -10 -35 9 -30 59 -26 63 6 4 29 -28 47 -53
    29z"/>
    <path d="M5021 7498 c-10 -30 -9 -31 4 -14 8 11 15 26 15 33 0 22 -8 14 -19
    -19z"/>
    <path d="M6632 7519 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
    -15 -5 -13 -11z"/>
    <path d="M2870 7510 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M6891 7496 c-9 -10 -9 -16 -1 -21 17 -10 37 12 24 25 -8 8 -15 6 -23
    -4z"/>
    <path d="M5526 7465 c-19 -20 -20 -26 -3 -49 8 -11 23 -17 38 -14 20 2 24 8
    24 38 0 28 -4 36 -21 38 -12 2 -29 -4 -38 -13z"/>
    <path d="M4572 7438 c-14 -14 -15 -47 -3 -66 14 -23 53 -10 64 21 15 45 -29
    77 -61 45z"/>
    <path d="M5854 7436 c-11 -28 1 -51 25 -51 32 0 49 28 32 49 -17 20 -50 21
    -57 2z"/>
    <path d="M6800 7436 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z"/>
    <path d="M10500 7430 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M4329 7368 c-17 -41 -2 -63 42 -63 31 0 34 3 34 29 0 22 -7 33 -32
    47 l-31 18 -13 -31z"/>
    <path d="M6205 7375 c-22 -13 -49 -25 -59 -25 -42 0 -108 -43 -136 -86 -32
    -50 -46 -119 -29 -140 6 -7 8 -17 5 -22 -3 -5 7 -29 23 -54 65 -102 206 -118
    295 -33 60 58 72 145 33 230 -13 28 -22 56 -19 63 7 22 -18 69 -44 81 -31 14
    -22 15 -69 -14z"/>
    <path d="M7830 7370 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M6620 7345 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
    -10 -7 -10 -15z"/>
    <path d="M6893 7334 c-3 -8 -1 -20 5 -26 16 -16 42 -2 42 22 0 24 -38 28 -47
    4z"/>
    <path d="M8182 7338 c-7 -7 -12 -20 -12 -30 0 -23 27 -78 39 -78 15 0 31 26
    37 60 6 26 3 36 -11 47 -23 16 -37 17 -53 1z"/>
    <path d="M8522 7309 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
    -15 -5 -13 -11z"/>
    <path d="M4152 7288 c5 -15 28 -18 29 -3 0 6 -7 12 -17 13 -10 3 -15 -1 -12
    -10z"/>
    <path d="M11723 7293 c-7 -2 -13 -16 -13 -29 0 -22 4 -25 28 -22 21 2 27 8 27
    28 0 24 -17 34 -42 23z"/>
    <path d="M7432 7247 c-32 -15 -65 -82 -57 -116 11 -45 35 -72 81 -91 31 -14
    44 -25 44 -39 0 -32 48 -80 91 -91 34 -9 46 -8 77 8 64 33 76 61 69 162 -2 26
    -7 34 -18 32 -9 -1 -29 5 -45 13 -16 8 -39 15 -51 15 -14 0 -23 6 -23 15 0 29
    -30 75 -60 90 -34 17 -75 18 -108 2z"/>
    <path d="M6360 7240 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M12183 7221 c-71 -45 -75 -123 -8 -155 50 -24 90 -20 125 12 66 59
    41 145 -45 157 -30 4 -48 1 -72 -14z"/>
    <path d="M11382 7213 c2 -10 10 -18 18 -18 22 0 18 29 -3 33 -14 3 -18 -1 -15
    -15z"/>
    <path d="M4827 7213 c-3 -5 -2 -15 3 -22 10 -17 43 -6 38 12 -6 15 -34 22 -41
    10z"/>
    <path d="M1642 7188 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
    -12z"/>
    <path d="M4632 7178 c-22 -22 -14 -57 13 -64 31 -8 75 3 75 18 0 6 8 20 18 31
    16 19 16 20 -5 15 -13 -4 -26 -2 -29 3 -8 13 -58 11 -72 -3z"/>
    <path d="M3965 7160 c-3 -5 -20 -16 -38 -25 -40 -19 -86 -76 -104 -129 -13
    -35 -12 -48 3 -105 22 -83 52 -123 123 -165 50 -28 64 -32 122 -30 74 2 113
    18 164 70 62 62 80 144 53 238 -16 54 -90 128 -144 144 -49 14 -170 16 -179 2z"/>
    <path d="M9785 7160 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
    -8 -4 -11 -10z"/>
    <path d="M10867 7164 c-4 -4 -7 -16 -7 -26 0 -14 6 -19 23 -16 14 2 22 10 22
    23 0 20 -25 32 -38 19z"/>
    <path d="M7836 7151 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
    -12 2 -15 -3z"/>
    <path d="M6450 7130 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M7175 7104 c-18 -18 -16 -33 7 -47 21 -14 48 -2 48 22 0 30 -34 45
    -55 25z"/>
    <path d="M5188 7101 c-47 -10 -62 -22 -89 -77 -21 -45 -21 -46 -3 -82 11 -20
    26 -42 34 -49 12 -10 9 -12 -22 -13 -53 0 -85 -18 -103 -57 -14 -28 -14 -39
    -3 -69 13 -38 61 -74 97 -74 81 0 129 96 80 158 -20 26 -20 27 -2 30 106 16
    133 43 133 130 0 41 -5 55 -29 81 -30 32 -33 33 -93 22z"/>
    <path d="M10595 7100 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
    0 -8 -4 -11 -10z"/>
    <path d="M10125 7081 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
    -9z"/>
    <path d="M62 7068 c-15 -18 1 -41 24 -36 23 4 26 41 3 46 -9 1 -21 -3 -27 -10z"/>
    <path d="M8479 7038 c-6 -13 -18 -27 -25 -31 -26 -15 -10 -67 21 -67 7 0 27
    19 45 41 26 32 31 45 23 58 -16 26 -50 25 -64 -1z"/>
    <path d="M3490 7030 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M2648 7019 c-24 -14 -23 -61 2 -84 45 -41 110 18 76 70 -17 26 -48
    32 -78 14z"/>
    <path d="M7885 7021 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
    <path d="M4898 6995 c-8 -19 -8 -28 2 -40 28 -33 88 -8 73 31 -15 41 -58 46
    -75 9z"/>
    <path d="M9442 6959 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
    3 -12 -1 -9 -9z"/>
    <path d="M4869 6903 c-19 -5 -31 -37 -22 -59 7 -20 50 -29 68 -14 21 18 19 57
    -4 70 -11 5 -21 9 -23 9 -2 -1 -10 -3 -19 -6z"/>
    <path d="M6831 6874 c-12 -15 -21 -30 -21 -35 0 -22 16 -43 43 -54 26 -11 29
    -17 31 -60 2 -62 37 -95 99 -95 64 1 97 36 97 105 0 29 -7 44 -32 67 -28 26
    -38 29 -75 25 -40 -4 -43 -3 -43 19 0 24 -33 54 -61 54 -10 0 -27 -12 -38 -26z"/>
    <path d="M9340 6884 c0 -9 7 -14 17 -12 25 5 28 28 4 28 -12 0 -21 -6 -21 -16z"/>
    <path d="M4560 6880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M9387 6864 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z"/>
    <path d="M8420 6850 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M10624 6849 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
    -16 -11z"/>
    <path d="M4423 6825 c-60 -75 -17 -185 72 -185 51 0 74 11 95 45 28 45 25 84
    -8 124 -24 29 -36 35 -83 38 -49 5 -56 3 -76 -22z"/>
    <path d="M5768 6820 c-24 -18 -28 -29 -28 -70 0 -42 4 -52 31 -74 35 -30 58
    -32 98 -11 53 27 65 110 21 150 -31 28 -90 31 -122 5z"/>
    <path d="M7309 6807 c-22 -14 -39 -37 -47 -62 -43 -128 152 -178 203 -53 22
    55 19 76 -14 109 -37 37 -92 39 -142 6z"/>
    <path d="M5529 6787 c-31 -21 -34 -28 -37 -77 -3 -51 0 -58 31 -88 l34 -33 44
    17 c24 9 49 22 54 29 6 6 16 11 23 9 7 -1 8 0 2 3 -6 3 -8 18 -4 38 4 23 0 44
    -15 72 -30 60 -73 70 -132 30z"/>
    <path d="M7912 6794 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
    -16z"/>
    <path d="M5410 6785 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
    -15 -7 -15 -15z"/>
    <path d="M2657 6776 c-3 -8 1 -20 9 -27 12 -10 18 -9 31 3 8 9 12 21 9 27 -10
    16 -43 14 -49 -3z"/>
    <path d="M7850 6760 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
    -15 -4 -15 -10z"/>
    <path d="M7672 6728 c-18 -18 -14 -28 8 -28 11 0 20 6 20 14 0 21 -15 27 -28
    14z"/>
    <path d="M7823 6705 c-19 -39 -7 -65 31 -65 47 0 63 57 24 84 -31 22 -36 21
    -55 -19z"/>
    <path d="M8450 6730 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M1103 6723 c-21 -8 -15 -43 6 -43 11 0 23 5 27 11 8 14 -17 38 -33
    32z"/>
    <path d="M9728 6719 c-10 -5 -18 -18 -18 -29 0 -10 -13 -32 -30 -48 -37 -38
    -38 -71 -4 -111 20 -24 34 -31 61 -31 67 0 123 93 77 131 -10 8 -13 21 -9 37
    11 45 -36 76 -77 51z"/>
    <path d="M10890 6700 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
    -16 -4 -16 -10z"/>
    <path d="M8325 6690 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
    -8 -4 -11 -10z"/>
    <path d="M3348 6679 c-22 -13 -24 -62 -2 -83 34 -34 84 -8 84 44 0 42 -43 62
    -82 39z"/>
    <path d="M7963 6683 c-16 -6 -17 -35 -1 -51 9 -9 9 -12 0 -12 -23 0 -62 -48
    -62 -76 0 -26 32 -65 64 -77 41 -16 96 31 96 82 0 24 -37 71 -56 71 -11 0 -12
    4 -2 19 9 15 9 23 -1 35 -14 17 -18 18 -38 9z"/>
    <path d="M8694 6676 c-10 -26 4 -48 28 -44 17 2 23 10 23 28 0 18 -6 26 -23
    28 -13 2 -25 -3 -28 -12z"/>
    <path d="M5160 6660 c-40 -40 -12 -102 46 -102 55 0 78 69 34 104 -29 23 -55
    23 -80 -2z"/>
    <path d="M8127 6666 c-7 -17 18 -40 33 -31 5 3 10 15 10 26 0 23 -35 27 -43 5z"/>
    <path d="M5297 6664 c-4 -4 -7 -16 -7 -26 0 -13 7 -18 26 -18 21 0 25 4 22 22
    -3 23 -27 35 -41 22z"/>
    <path d="M9267 6646 c-7 -18 1 -26 25 -26 13 0 18 6 16 17 -4 22 -34 29 -41 9z"/>
    <path d="M2859 6628 c-10 -18 -8 -24 7 -35 21 -15 34 -13 48 9 7 12 6 21 -3
    32 -19 22 -38 20 -52 -6z"/>
    <path d="M7854 6619 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
    -16 -11z"/>
    <path d="M8336 6604 c-24 -23 -26 -28 -13 -41 12 -12 18 -12 34 -2 40 24 52
    69 20 69 -7 0 -25 -12 -41 -26z"/>
    <path d="M11450 6600 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M9412 6578 c-33 -33 1 -78 47 -63 24 7 31 24 20 53 -9 24 -47 30 -67
    10z"/>
    <path d="M8265 6566 c-20 -15 -15 -46 8 -46 20 0 40 27 33 45 -7 19 -17 19
    -41 1z"/>
    <path d="M5721 6544 c-27 -35 -26 -59 4 -89 47 -48 115 -19 115 48 0 66 -78
    93 -119 41z"/>
    <path d="M7307 6526 c-3 -8 1 -20 9 -27 12 -10 18 -9 31 3 8 9 12 21 9 27 -10
    16 -43 14 -49 -3z"/>
    <path d="M6753 6520 c-18 -4 -39 -13 -47 -20 -29 -24 -55 -95 -57 -157 -2 -65
    10 -93 40 -93 9 0 35 -7 58 -15 109 -38 236 51 218 154 -11 68 -26 91 -71 111
    -51 24 -97 30 -141 20z"/>
    <path d="M9299 6508 c-7 -34 -7 -94 2 -104 6 -7 27 -16 48 -20 20 -3 46 -13
    59 -21 30 -18 60 -8 68 23 8 32 -7 48 -75 80 -31 15 -64 31 -73 35 -18 9 -28
    11 -29 7z"/>
    <path d="M8890 6475 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
    -10 -7 -10 -15z"/>
    <path d="M3890 6440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M318 6423 c-121 -51 -158 -163 -85 -257 43 -54 98 -76 167 -65 96 15
    150 94 135 196 -6 39 -15 56 -50 89 -36 35 -51 42 -90 46 -26 2 -60 -2 -77 -9z"/>
    <path d="M7337 6424 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z"/>
    <path d="M8226 6414 c-9 -8 -16 -18 -16 -22 1 -38 63 -42 68 -5 4 30 -31 49
    -52 27z"/>
    <path d="M5642 6408 c-29 -29 -1 -78 44 -78 32 0 51 38 35 69 -13 23 -59 29
    -79 9z"/>
    <path d="M8326 6388 c-26 -36 -20 -85 13 -119 51 -50 121 -29 121 37 0 41 -22
    89 -45 98 -35 13 -74 6 -89 -16z"/>
    <path d="M3164 6385 c-9 -23 3 -45 25 -45 23 0 35 28 20 46 -15 18 -38 18 -45
    -1z"/>
    <path d="M7555 6380 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
    -12 6 -17 -2z"/>
    <path d="M9221 6364 c-34 -28 -40 -63 -19 -108 10 -23 8 -29 -15 -50 -56 -52
    -22 -139 58 -150 47 -7 57 -2 79 42 16 32 16 35 -3 68 -12 19 -21 37 -21 39 0
    3 16 11 35 19 59 25 63 105 6 143 -44 30 -82 29 -120 -3z"/>
    <path d="M6122 6363 c2 -10 10 -18 18 -18 8 0 16 8 18 18 2 12 -3 17 -18 17
    -15 0 -20 -5 -18 -17z"/>
    <path d="M10342 6358 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
    -12z"/>
    <path d="M6373 6334 c-65 -51 -23 -136 52 -108 30 12 37 52 18 95 -10 21 -22
    39 -28 39 -5 0 -25 -12 -42 -26z"/>
    <path d="M6980 6335 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
    0 -20 -7 -20 -15z"/>
    <path d="M8740 6336 c0 -8 4 -17 9 -20 11 -7 33 18 24 27 -12 12 -33 7 -33 -7z"/>
    <path d="M7182 6328 c-16 -16 -15 -33 3 -48 24 -20 67 3 63 33 -3 25 -45 36
    -66 15z"/>
    <path d="M6120 6317 c-13 -7 -29 -25 -35 -41 -8 -25 -6 -31 18 -52 30 -26 76
    -31 95 -12 13 13 16 76 4 95 -12 19 -55 24 -82 10z"/>
    <path d="M7600 6292 c-24 -19 -21 -42 5 -42 14 0 35 29 35 49 0 16 -15 13 -40
    -7z"/>
    <path d="M5264 6275 c-4 -8 -3 -22 0 -31 8 -21 50 -14 54 10 6 31 -43 50 -54
    21z"/>
    <path d="M5731 6281 c-15 -10 -8 -41 9 -41 15 0 25 29 14 40 -5 5 -15 5 -23 1z"/>
    <path d="M8702 6268 c-33 -33 6 -96 52 -84 29 7 45 49 29 75 -13 20 -64 26
    -81 9z"/>
    <path d="M12150 6255 c-20 -25 -8 -50 25 -50 24 0 31 5 33 24 6 37 -34 55 -58
    26z"/>
    <path d="M5412 6223 c2 -10 10 -18 18 -18 8 0 16 8 18 18 2 12 -3 17 -18 17
    -15 0 -20 -5 -18 -17z"/>
    <path d="M4560 6180 c-6 -12 -9 -24 -7 -27 3 -2 11 5 17 17 6 12 9 24 7 27 -3
    2 -11 -5 -17 -17z"/>
    <path d="M6055 6191 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
    -9z"/>
    <path d="M6754 6174 c-9 -34 15 -59 49 -50 51 12 40 76 -13 76 -24 0 -31 -5
    -36 -26z"/>
    <path d="M7530 6185 c-7 -8 -10 -25 -6 -40 5 -20 13 -25 36 -25 19 0 33 7 40
    19 13 25 13 27 -6 45 -20 20 -48 20 -64 1z"/>
    <path d="M7720 6180 c0 -16 5 -21 23 -18 12 2 22 10 22 18 0 8 -10 16 -22 18
    -18 3 -23 -2 -23 -18z"/>
    <path d="M7186 6173 c-4 -9 0 -23 9 -32 13 -14 17 -14 31 1 9 9 14 24 11 32
    -8 21 -43 20 -51 -1z"/>
    <path d="M7642 6178 c-31 -31 10 -75 43 -48 19 16 19 25 -1 44 -19 19 -26 20
    -42 4z"/>
    <path d="M5612 6164 c-27 -18 -29 -55 -4 -77 37 -34 92 -11 92 38 0 23 -32 55
    -55 55 -6 0 -21 -7 -33 -16z"/>
    <path d="M2899 6155 c-34 -18 -51 -59 -43 -101 6 -32 26 -58 67 -87 16 -11 17
    -16 6 -42 -19 -46 -19 -76 3 -131 17 -45 26 -55 71 -77 112 -55 220 -4 241
    115 11 59 -31 131 -94 163 -27 14 -56 25 -65 25 -10 0 -15 10 -15 35 0 19 -5
    46 -12 60 -23 50 -103 70 -159 40z"/>
    <path d="M6865 6149 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
    <path d="M3450 6135 c-10 -12 -10 -18 0 -30 20 -25 55 -15 55 15 0 30 -35 40
    -55 15z"/>
    <path d="M5265 6122 c-33 -23 -41 -35 -43 -64 -4 -50 24 -78 77 -78 31 0 44 6
    60 26 12 15 21 34 21 42 0 23 -49 102 -63 102 -7 -1 -30 -13 -52 -28z"/>
    <path d="M5493 6143 c-21 -8 -15 -43 6 -43 11 0 23 5 27 11 8 14 -17 38 -33
    32z"/>
    <path d="M8702 6138 c-26 -26 -3 -88 32 -88 17 0 42 22 49 43 13 37 -53 73
    -81 45z"/>
    <path d="M10167 6132 c-22 -25 -21 -75 1 -95 23 -21 86 -22 106 -1 9 8 16 28
    16 44 0 56 -87 92 -123 52z"/>
    <path d="M3924 6125 c-7 -18 3 -35 21 -35 21 0 28 19 15 35 -16 19 -29 19 -36
    0z"/>
    <path d="M9087 6133 c-15 -14 -7 -43 12 -43 23 0 34 23 18 39 -13 13 -21 14
    -30 4z"/>
    <path d="M3610 6108 c0 -7 5 -20 10 -28 8 -12 10 -11 10 7 0 12 -4 25 -10 28
    -5 3 -10 0 -10 -7z"/>
    <path d="M6403 6106 c-44 -21 -35 -66 13 -66 29 0 37 13 29 50 -7 32 -9 33
    -42 16z"/>
    <path d="M7084 6109 c-3 -6 0 -17 8 -25 13 -13 16 -13 27 0 7 8 10 20 6 26 -8
    13 -32 13 -41 -1z"/>
    <path d="M6951 6071 c-19 -12 -6 -43 16 -39 10 2 18 12 18 22 0 22 -14 29 -34
    17z"/>
    <path d="M8540 6065 c-16 -20 -6 -45 19 -45 20 0 37 32 26 50 -9 15 -31 12
    -45 -5z"/>
    <path d="M9350 6060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M7453 6053 c-19 -7 -16 -53 3 -60 18 -7 45 6 51 24 4 11 -23 44 -35
    42 -4 0 -13 -3 -19 -6z"/>
    <path d="M7826 6014 c-37 -37 -1 -103 49 -90 14 4 31 17 37 31 23 52 -46 100
    -86 59z"/>
    <path d="M8712 5987 c-49 -50 -68 -101 -52 -142 9 -22 7 -29 -11 -44 -15 -12
    -20 -23 -15 -38 9 -28 32 -36 50 -17 13 13 19 12 53 -6 135 -72 284 73 215
    210 -26 53 -74 80 -141 80 -54 0 -59 -2 -99 -43z"/>
    <path d="M3960 6009 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
    -5 -10 -11z"/>
    <path d="M6115 6000 c-9 -15 14 -40 31 -33 22 8 18 43 -5 43 -11 0 -23 -5 -26
    -10z"/>
    <path d="M7600 5980 c-26 -26 -25 -46 5 -75 29 -30 58 -32 85 -5 26 26 25 52
    -3 78 -29 27 -61 28 -87 2z"/>
    <path d="M6767 5984 c-13 -13 -7 -24 14 -24 11 0 19 5 17 12 -5 14 -22 21 -31
    12z"/>
    <path d="M7067 5984 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z"/>
    <path d="M4080 5930 c-24 -24 -25 -48 -4 -78 31 -44 104 -16 104 40 0 24 -35
    58 -60 58 -11 0 -29 -9 -40 -20z"/>
    <path d="M7336 5937 c-24 -18 -17 -51 12 -55 12 -2 27 -12 32 -23 12 -21 35
    -25 45 -8 4 5 0 18 -9 28 -9 10 -16 24 -16 32 0 18 -19 39 -35 39 -6 0 -19 -6
    -29 -13z"/>
    <path d="M12770 5934 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
    -10 -7 -10 -16z"/>
    <path d="M880 5930 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
    -15 -4 -15 -10z"/>
    <path d="M3905 5920 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z"/>
    <path d="M5814 5902 c-17 -15 -41 -42 -53 -60 -22 -31 -23 -37 -12 -90 13 -66
    47 -107 102 -124 49 -14 101 -3 140 29 39 33 52 65 50 128 -2 75 -57 126 -151
    138 -39 6 -49 3 -76 -21z"/>
    <path d="M8005 5920 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
    <path d="M8262 5918 c-7 -7 -12 -21 -12 -33 0 -44 60 -63 80 -26 14 27 13 37
    -6 55 -18 19 -45 21 -62 4z"/>
    <path d="M9517 5863 c-4 -3 -7 -17 -7 -30 0 -16 -6 -23 -19 -23 -20 0 -35 -25
    -25 -41 10 -17 46 -9 51 11 3 11 13 20 23 20 25 0 43 25 35 50 -6 19 -43 28
    -58 13z"/>
    <path d="M624 5844 c-57 -28 -73 -111 -30 -157 29 -31 64 -42 112 -33 63 12
    94 95 57 156 -29 49 -82 62 -139 34z"/>
    <path d="M3990 5840 c0 -11 6 -20 13 -20 19 0 27 10 20 26 -8 21 -33 17 -33
    -6z"/>
    <path d="M6670 5836 c-7 -9 -10 -27 -8 -40 4 -20 0 -25 -24 -30 -45 -9 -88
    -60 -88 -105 0 -100 115 -144 186 -70 35 37 38 95 6 139 -18 24 -19 29 -6 34
    11 4 14 17 12 43 -3 34 -6 38 -34 41 -19 2 -36 -3 -44 -12z"/>
    <path d="M3920 5830 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M7910 5821 c0 -26 27 -34 39 -12 12 21 5 31 -21 31 -11 0 -18 -7 -18
    -19z"/>
    <path d="M9885 5830 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
    -7 -4 -4 -10z"/>
    <path d="M6940 5795 c-10 -11 -10 -19 -2 -27 15 -15 44 -2 40 19 -4 23 -22 27
    -38 8z"/>
    <path d="M6830 5772 l-23 -19 21 -21 c26 -26 45 -28 66 -6 19 19 20 36 4 52
    -17 17 -42 15 -68 -6z"/>
    <path d="M5516 5772 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
    <path d="M9370 5746 c-27 -33 18 -72 48 -42 13 13 14 20 6 34 -15 23 -38 26
    -54 8z"/>
    <path d="M11520 5740 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
    0 -15 -4 -15 -10z"/>
    <path d="M5255 5725 l-30 -13 37 -7 c47 -9 88 1 88 20 0 19 -53 19 -95 0z"/>
    <path d="M6922 5719 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
    -15 -5 -13 -11z"/>
    <path d="M816 5699 c-35 -28 -36 -80 -1 -114 47 -48 120 -21 131 47 12 72 -70
    114 -130 67z"/>
    <path d="M8182 5694 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
    -16z"/>
    <path d="M3954 5675 c-4 -8 -10 -12 -16 -9 -19 12 -59 -7 -69 -33 -7 -19 -6
    -31 6 -49 32 -50 103 -14 92 46 -4 17 -2 30 3 30 6 0 10 7 10 15 0 19 -19 19
    -26 0z"/>
    <path d="M8020 5668 c-54 -15 -62 -26 -55 -72 9 -55 43 -86 96 -86 78 0 117
    82 68 144 -22 28 -48 32 -109 14z"/>
    <path d="M3196 5655 c-15 -8 -36 -15 -46 -15 -10 0 -20 -7 -24 -15 -3 -8 -15
    -15 -27 -15 -27 0 -94 -68 -114 -117 -22 -51 -19 -92 11 -151 21 -42 36 -58
    75 -77 69 -36 134 -34 186 4 100 72 128 163 79 253 -17 32 -21 50 -14 54 7 4
    5 10 -6 16 -9 5 -16 17 -16 27 0 23 -31 51 -56 51 -10 0 -32 -7 -48 -15z"/>
    <path d="M4887 5656 c-16 -7 -45 -27 -65 -44 -20 -18 -51 -41 -69 -52 -58 -35
    -87 -121 -63 -184 7 -19 4 -36 -11 -72 -13 -28 -19 -59 -16 -77 3 -21 -2 -40
    -15 -58 -18 -24 -18 -29 -4 -62 10 -24 23 -38 40 -43 14 -3 27 -10 27 -15 3
    -20 5 -24 12 -36 12 -19 57 -16 64 3 3 9 1 25 -6 36 -7 11 -9 28 -5 40 6 20 6
    20 18 -3 6 -13 24 -28 38 -33 51 -18 91 50 52 88 -20 21 -53 20 -75 0 -17 -15
    -19 -14 -25 10 -9 37 -1 41 57 28 28 -7 72 -12 98 -12 47 0 134 30 146 50 3 6
    20 10 36 10 17 0 32 6 35 15 4 8 12 15 19 15 7 0 23 8 36 19 21 16 22 22 11
    45 -6 14 -20 26 -30 26 -9 0 -28 7 -40 16 -22 15 -22 17 -6 35 9 10 22 19 29
    19 7 0 20 9 31 21 11 13 26 18 39 15 11 -3 43 -3 72 1 63 7 75 26 38 60 -14
    13 -25 28 -25 32 0 12 -57 31 -94 31 -46 0 -82 22 -78 47 4 31 -16 38 -47 15
    -33 -24 -87 -37 -96 -23 -7 13 -50 36 -80 44 -11 3 -33 0 -48 -7z"/>
    <path d="M6065 5661 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
    <path d="M6307 5664 c-13 -13 -7 -52 8 -58 27 -10 46 5 43 33 -3 26 -35 41
    -51 25z"/>
    <path d="M8310 5645 c-15 -18 -6 -45 13 -45 8 0 20 7 27 15 10 12 10 18 0 30
    -16 19 -24 19 -40 0z"/>
    <path d="M10930 5650 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M1340 5626 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
    -10 -1 -10 -9z"/>
    <path d="M8836 5622 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
    <path d="M4496 5604 c-32 -32 -7 -78 39 -72 24 2 31 8 33 31 6 50 -38 75 -72
    41z"/>
    <path d="M7087 5613 c-11 -11 -8 -48 5 -61 30 -30 75 0 64 43 -5 19 -13 25
    -35 25 -15 0 -31 -3 -34 -7z"/>
    <path d="M970 5590 c-11 -11 -20 -27 -20 -35 0 -18 35 -55 53 -55 24 0 57 31
    57 55 0 51 -54 71 -90 35z"/>
    <path d="M10374 5589 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
    -16 -11z"/>
    <path d="M4024 5559 c-8 -14 18 -31 30 -19 9 9 0 30 -14 30 -5 0 -12 -5 -16
    -11z"/>
    <path d="M10840 5545 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
    0 -15 -7 -15 -15z"/>
    <path d="M8421 5531 c-13 -24 4 -46 42 -55 33 -8 42 8 28 47 -13 32 -55 37
    -70 8z"/>
    <path d="M5436 5514 c-9 -8 -16 -26 -16 -38 0 -23 28 -56 47 -56 6 0 25 -8 41
    -17 25 -14 32 -15 46 -4 26 22 19 51 -24 92 -43 42 -69 48 -94 23z"/>
    <path d="M8664 5506 c-3 -7 -4 -17 -3 -22 4 -22 -2 -33 -22 -39 -29 -9 -44
    -47 -30 -73 22 -40 142 -57 161 -23 19 37 9 131 -15 131 -6 0 -20 9 -30 20
    -21 23 -53 26 -61 6z"/>
    <path d="M10210 5510 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M9259 5494 c-17 -21 0 -49 32 -49 18 0 25 6 27 24 6 38 -34 55 -59
    25z"/>
    <path d="M5930 5485 c-7 -8 -20 -15 -30 -15 -28 0 -43 -25 -30 -49 14 -26 46
    -27 60 -2 6 12 17 17 29 14 26 -7 48 32 30 53 -15 18 -43 18 -59 -1z"/>
    <path d="M1176 5468 c-25 -36 -20 -66 15 -87 17 -10 37 -28 45 -40 17 -23 63
    -28 82 -9 25 25 7 98 -23 98 -8 0 -15 6 -15 14 0 20 -39 46 -67 46 -12 0 -29
    -10 -37 -22z"/>
    <path d="M5590 5475 c-15 -18 -4 -52 19 -60 40 -12 70 20 51 56 -13 22 -52 25
    -70 4z"/>
    <path d="M9887 5477 c-8 -21 8 -39 29 -35 27 5 25 42 -2 46 -12 2 -24 -3 -27
    -11z"/>
    <path d="M9985 5472 c-42 -26 -65 -64 -65 -107 0 -76 59 -133 125 -122 19 3
    44 -1 60 -9 36 -18 59 -18 76 2 11 13 11 21 -3 45 -11 19 -14 37 -9 56 10 38
    -6 97 -35 124 -33 31 -109 37 -149 11z"/>
    <path d="M12647 5483 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"/>
    <path d="M5850 5469 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
    -5 -10 -11z"/>
    <path d="M9673 5463 c-19 -7 -16 -53 3 -60 18 -7 45 6 51 24 4 11 -23 44 -35
    42 -4 0 -13 -3 -19 -6z"/>
    <path d="M4125 5451 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
    <path d="M6364 5435 c-4 -9 -2 -21 4 -27 15 -15 44 -1 40 19 -4 23 -36 29 -44
    8z"/>
    <path d="M7212 5398 c-7 -7 -12 -28 -12 -48 0 -30 5 -39 25 -48 40 -18 62 -14
    84 14 27 34 26 46 -2 72 -25 24 -76 29 -95 10z"/>
    <path d="M7930 5400 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M3585 5378 c-7 -17 -18 -23 -40 -22 -16 1 -58 -3 -93 -8 -53 -9 -62
    -13 -57 -28 3 -9 1 -47 -4 -85 -9 -64 -9 -69 12 -86 19 -16 20 -20 9 -39 -18
    -30 -8 -36 18 -10 26 26 40 18 40 -21 0 -32 6 -35 34 -14 11 8 29 12 42 9 29
    -7 88 21 102 49 22 45 22 64 2 90 -11 14 -20 35 -20 47 0 12 -9 33 -19 46 -13
    17 -17 34 -14 59 5 41 2 44 -12 13z"/>
    <path d="M3987 5386 c-4 -9 0 -13 11 -12 9 2 18 8 20 14 5 17 -25 15 -31 -2z"/>
    <path d="M5564 5385 c-4 -9 -2 -21 4 -27 15 -15 44 -1 40 19 -4 23 -36 29 -44
    8z"/>
    <path d="M6820 5381 c-24 -45 4 -91 56 -91 41 0 66 60 38 91 -23 26 -80 26
    -94 0z"/>
    <path d="M7866 5393 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
    <path d="M8200 5385 c-26 -31 13 -82 44 -56 19 16 21 42 4 59 -16 16 -33 15
    -48 -3z"/>
    <path d="M10250 5390 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M2535 5370 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z"/>
    <path d="M7802 5368 c3 -7 13 -15 22 -16 12 -3 17 1 14 10 -3 7 -13 15 -22 16
    -12 3 -17 -1 -14 -10z"/>
    <path d="M5987 5353 c-13 -12 -7 -50 9 -63 11 -10 23 -11 42 -4 49 17 36 74
    -18 74 -14 0 -30 -3 -33 -7z"/>
    <path d="M6553 5334 c-8 -20 8 -48 30 -52 22 -5 42 35 26 54 -16 19 -48 18
    -56 -2z"/>
    <path d="M5280 5325 c-20 -24 -3 -55 30 -55 20 0 31 6 35 20 8 25 -10 50 -35
    50 -10 0 -23 -7 -30 -15z"/>
    <path d="M5797 5333 c-4 -3 -7 -21 -7 -39 0 -63 79 -84 101 -27 10 28 -1 57
    -27 67 -21 8 -59 8 -67 -1z"/>
    <path d="M8297 5334 c-10 -10 -9 -42 2 -48 18 -11 42 6 39 28 -3 21 -28 33
    -41 20z"/>
    <path d="M2595 5300 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z"/>
    <path d="M2846 5294 c-24 -23 -3 -56 32 -52 20 2 28 9 30 27 6 38 -33 54 -62
    25z"/>
    <path d="M5905 5280 c-9 -15 4 -30 26 -30 12 0 19 7 19 20 0 21 -33 29 -45 10z"/>
    <path d="M2661 5266 c-9 -10 -8 -16 4 -26 19 -16 41 1 32 24 -8 20 -21 20 -36
    2z"/>
    <path d="M6160 5260 c0 -16 5 -21 23 -18 12 2 22 10 22 18 0 8 -10 16 -22 18
    -18 3 -23 -2 -23 -18z"/>
    <path d="M6023 5254 c-3 -8 2 -23 11 -32 15 -15 17 -15 32 0 21 21 11 48 -16
    48 -11 0 -23 -7 -27 -16z"/>
    <path d="M8430 5255 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
    -15 -7 -15 -15z"/>
    <path d="M1585 5240 c-34 -37 7 -100 56 -85 11 4 27 17 35 31 15 22 15 26 0
    49 -20 31 -65 34 -91 5z"/>
    <path d="M3794 5245 c-7 -17 12 -45 31 -45 19 0 36 25 29 44 -8 20 -53 21 -60
    1z"/>
    <path d="M4245 5250 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
    -8 -4 -11 -10z"/>
    <path d="M9357 5212 c-33 -36 -8 -92 40 -92 25 0 53 30 53 57 0 46 -62 69 -93
    35z"/>
    <path d="M8320 5206 c-20 -25 -8 -61 21 -64 36 -4 55 21 41 53 -13 28 -43 33
    -62 11z"/>
    <path d="M10255 5210 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30
    1z"/>
    <path d="M4533 5200 c-26 -11 -41 -56 -26 -79 5 -9 21 -22 35 -30 23 -13 29
    -13 52 2 34 23 37 78 4 101 -25 18 -33 19 -65 6z"/>
    <path d="M7760 5200 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M5752 5176 c-51 -28 -75 -93 -51 -139 45 -87 143 -101 199 -29 68 85
    -52 222 -148 168z"/>
    <path d="M7700 5180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M9967 5172 c-35 -38 -13 -92 37 -92 18 0 36 3 40 8 4 4 20 7 34 7 36
    0 42 32 8 44 -13 6 -30 19 -36 31 -14 26 -61 27 -83 2z"/>
    <path d="M4275 5170 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
    -13 -4 -16 -10z"/>
    <path d="M8525 5168 c-54 -29 -60 -84 -16 -129 45 -45 118 -36 140 18 15 37 3
    72 -35 102 -29 23 -58 26 -89 9z"/>
    <path d="M10220 5170 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M6135 5161 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33 14z"/>
    <path d="M8784 5146 c-3 -8 -3 -20 0 -25 4 -6 1 -19 -6 -30 -11 -17 -10 -23 4
    -37 29 -30 47 -11 45 48 -2 41 -6 53 -20 56 -9 2 -20 -3 -23 -12z"/>
    <path d="M6336 5131 c-11 -17 6 -41 29 -41 24 0 35 16 28 38 -5 14 -49 16 -57
    3z"/>
    <path d="M7870 5119 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
    -5 -10 -11z"/>
    <path d="M8281 5112 c-8 -15 -7 -23 5 -33 17 -14 31 -4 36 26 4 26 -28 32 -41
    7z"/>
    <path d="M4122 5103 c-13 -26 -4 -43 24 -43 19 0 24 5 24 24 0 13 -7 27 -16
    30 -22 8 -22 8 -32 -11z"/>
    <path d="M10142 5087 c-12 -13 -22 -35 -22 -49 0 -19 -9 -31 -33 -45 -20 -12
    -33 -28 -34 -43 -3 -38 -17 -42 -53 -15 -25 18 -47 25 -80 25 -54 0 -87 -20
    -106 -66 -19 -46 -18 -78 6 -126 11 -23 20 -47 20 -54 0 -6 9 -22 20 -36 13
    -18 16 -28 8 -36 -5 -5 -7 -15 -4 -21 10 -16 36 -14 36 3 0 8 16 16 39 20 43
    7 77 40 87 85 12 55 15 57 32 28 18 -31 68 -61 117 -71 88 -18 195 79 195 177
    0 42 -56 122 -97 138 -14 5 -23 17 -23 29 0 70 -64 104 -108 57z"/>
    <path d="M12312 5089 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
    0 -15 -5 -13 -11z"/>
    <path d="M10640 5075 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
    -13z"/>
    <path d="M9400 5070 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
    -15 -4 -15 -10z"/>
    <path d="M6367 5064 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
    17z"/>
    <path d="M6904 5036 c-42 -42 -45 -85 -9 -139 20 -29 34 -38 65 -43 22 -4 40
    -9 40 -13 0 -3 -11 -18 -24 -33 -38 -40 -56 -91 -46 -130 12 -52 31 -86 57
    -103 12 -9 23 -22 23 -29 0 -28 28 -40 66 -28 31 9 36 8 50 -13 28 -43 94 -38
    115 9 9 19 8 31 -4 55 -14 27 -14 35 -1 59 22 42 18 128 -8 164 -26 37 -90 67
    -140 68 l-37 0 24 36 c40 59 29 120 -27 154 -52 32 -103 27 -144 -14z"/>
    <path d="M8705 5044 c-18 -18 -16 -33 7 -47 35 -22 66 21 36 51 -16 16 -23 15
    -43 -4z"/>
    <path d="M10014 5035 c-9 -23 14 -45 26 -25 5 8 8 19 7 24 -6 17 -27 18 -33 1z"/>
    <path d="M8084 5017 c3 -11 -2 -33 -10 -48 -16 -32 -19 -75 -4 -84 6 -3 10
    -15 10 -26 0 -12 4 -17 11 -14 6 4 20 -2 32 -13 12 -11 44 -23 70 -27 43 -6
    49 -4 54 14 3 11 11 22 19 25 8 3 14 12 14 21 0 8 5 24 11 34 17 26 4 64 -35
    106 -33 35 -35 35 -106 34 -67 -1 -71 -3 -66 -22z"/>
    <path d="M7372 5005 c-12 -14 -28 -25 -35 -25 -23 0 -64 -54 -71 -94 -9 -49 6
    -85 47 -113 85 -58 197 -1 197 100 0 33 -32 92 -55 101 -9 3 -20 17 -25 31
    -12 32 -30 32 -58 0z"/>
    <path d="M4235 5010 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
    -8 -4 -11 -10z"/>
    <path d="M8454 5005 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
    10z"/>
    <path d="M9960 5005 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
    -10 -7 -10 -15z"/>
    <path d="M5250 4995 c-19 -23 -3 -55 30 -55 33 0 47 32 23 55 -18 19 -37 19
    -53 0z"/>
    <path d="M6542 4993 c4 -22 43 -25 43 -3 0 8 -11 16 -23 18 -19 3 -23 0 -20
    -15z"/>
    <path d="M4454 4986 c-10 -26 0 -51 21 -51 28 0 32 40 5 55 -16 9 -21 8 -26
    -4z"/>
    <path d="M6620 4975 c-11 -13 -11 -18 2 -31 20 -20 50 -7 46 20 -4 26 -30 32
    -48 11z"/>
    <path d="M8402 4958 c-7 -7 -12 -25 -12 -40 0 -21 6 -31 26 -38 32 -13 41 -7
    49 31 11 48 -31 79 -63 47z"/>
    <path d="M7834 4949 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
    -16 -11z"/>
    <path d="M10385 4940 c-11 -17 5 -32 21 -19 7 6 11 15 8 20 -7 12 -21 11 -29
    -1z"/>
    <path d="M12135 4939 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
    <path d="M8577 4933 c-13 -12 -7 -44 9 -50 23 -9 48 25 33 44 -12 14 -32 17
    -42 6z"/>
    <path d="M5916 4914 c-9 -8 -16 -19 -16 -24 0 -13 30 -40 45 -40 15 0 45 27
    45 40 0 13 -30 40 -45 40 -7 0 -21 -7 -29 -16z"/>
    <path d="M6128 4915 c-44 -24 -82 -69 -105 -123 -18 -44 -19 -58 -11 -94 6
    -24 17 -50 24 -59 8 -8 14 -20 14 -26 0 -5 9 -18 20 -28 11 -10 20 -27 20 -38
    0 -11 11 -35 25 -53 14 -18 25 -40 25 -48 0 -23 36 -39 60 -26 20 10 22 16 24
    53 1 13 17 24 55 37 65 24 81 37 110 95 31 63 36 104 17 168 -34 116 -184 193
    -278 142z"/>
    <path d="M11854 4919 c-9 -16 18 -30 33 -17 7 6 10 14 7 19 -7 13 -31 11 -40
    -2z"/>
    <path d="M6566 4891 c-10 -11 -24 -17 -32 -14 -37 14 -104 -44 -104 -90 0 -32
    35 -59 77 -61 48 -1 79 22 88 64 3 15 16 32 30 38 29 13 33 43 9 66 -21 21
    -47 20 -68 -3z"/>
    <path d="M3275 4880 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z"/>
    <path d="M4511 4866 c-6 -13 -17 -28 -25 -33 -8 -4 -17 -20 -21 -34 -6 -24 -1
    -31 48 -63 30 -20 65 -36 79 -36 63 0 105 78 74 138 -17 33 -25 37 -99 47 -44
    6 -48 4 -56 -19z"/>
    <path d="M5512 4864 c-30 -20 -31 -88 -3 -104 49 -26 111 4 111 53 0 30 -35
    67 -63 67 -12 0 -33 -7 -45 -16z"/>
    <path d="M9672 4868 c-20 -20 -15 -66 9 -78 26 -14 69 11 69 40 0 39 -51 65
    -78 38z"/>
    <path d="M4031 4856 c-70 -25 -121 -109 -107 -177 5 -26 4 -27 -27 -23 -28 5
    -36 0 -70 -40 -33 -39 -42 -45 -65 -40 -19 4 -23 3 -14 -4 15 -10 17 -58 7
    -145 -7 -50 -14 -65 -54 -110 -43 -49 -44 -50 -8 -19 38 32 57 40 57 22 0 -5
    25 -28 56 -50 30 -23 58 -48 61 -56 8 -21 -30 -66 -68 -78 -28 -9 -34 -17 -37
    -41 -7 -85 -10 -90 -62 -110 -85 -33 -49 -62 40 -32 28 10 35 17 36 39 1 34
    27 75 53 83 11 3 24 20 30 37 16 46 41 71 57 57 10 -8 9 -17 -7 -42 -35 -58
    -18 -115 39 -135 71 -24 129 50 103 131 -7 19 -18 38 -26 41 -26 10 -16 23 30
    40 79 31 112 101 80 170 -10 22 -15 46 -11 61 4 15 0 37 -9 55 -23 44 -19 56
    23 68 21 7 47 21 57 32 10 11 28 20 40 20 17 0 24 7 29 31 3 17 1 39 -4 49 -6
    10 -10 23 -10 28 0 12 130 68 147 64 15 -3 40 44 29 55 -3 4 -12 1 -18 -5 -9
    -9 -20 -6 -45 9 -39 24 -50 20 -33 -11 14 -27 2 -44 -52 -71 l-38 -19 -20 32
    c-11 18 -20 38 -20 45 0 11 -99 54 -123 52 -7 0 -27 -6 -46 -13z"/>
    <path d="M8894 4815 c-20 -31 -10 -63 23 -71 34 -9 57 6 61 40 7 55 -53 78
    -84 31z"/>
    <path d="M6641 4789 c-18 -24 -20 -33 -11 -39 7 -5 20 -4 29 0 9 5 24 11 34
    14 25 8 19 50 -8 54 -14 2 -29 -8 -44 -29z"/>
    <path d="M11850 4810 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M12220 4810 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M9580 4794 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z"/>
    <path d="M8315 4791 c-6 -5 -28 -11 -49 -13 -46 -4 -67 -28 -50 -55 17 -28 53
    -28 83 0 14 13 34 27 44 31 11 3 17 13 15 23 -4 20 -29 28 -43 14z"/>
    <path d="M9120 4780 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M3787 4774 c-12 -12 -8 -42 6 -47 21 -8 39 8 35 29 -3 19 -29 30 -41
    18z"/>
    <path d="M8662 4758 c2 -15 10 -23 23 -23 30 0 27 39 -3 43 -20 3 -23 0 -20
    -20z"/>
    <path d="M9461 4762 c-12 -23 4 -52 29 -52 26 0 45 36 29 56 -16 19 -46 18
    -58 -4z"/>
    <path d="M4875 4761 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
    -9z"/>
    <path d="M5752 4759 c-47 -18 -48 -84 -2 -109 28 -15 48 -12 70 10 47 47 -4
    123 -68 99z"/>
    <path d="M6695 4750 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
    -12 6 -17 -2z"/>
    <path d="M8041 4736 c-8 -10 -7 -17 3 -26 17 -13 36 -6 36 15 0 19 -27 26 -39
    11z"/>
    <path d="M3510 4710 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
    -15 -4 -15 -10z"/>
    <path d="M8164 4703 c-4 -15 -13 -18 -45 -15 -34 4 -46 -1 -70 -24 -28 -27
    -40 -74 -19 -74 26 0 6 -29 -27 -38 -93 -27 -110 -36 -131 -73 -15 -26 -22
    -55 -22 -89 0 -47 4 -56 37 -88 21 -21 42 -46 48 -57 13 -24 44 -32 56 -14 6
    10 10 10 23 0 19 -16 36 -6 36 20 0 11 8 23 18 26 9 4 25 17 35 30 10 13 25
    23 33 23 8 0 22 4 32 8 9 5 35 17 57 28 39 19 40 20 41 72 0 29 -3 48 -6 41
    -6 -8 -13 -8 -30 1 -12 7 -33 10 -47 7 -20 -4 -26 2 -38 31 -14 33 -14 36 14
    68 20 22 28 40 24 53 -4 15 -1 21 11 21 13 0 17 7 14 27 -4 33 -37 45 -44 16z"/>
    <path d="M5187 4684 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
    15z"/>
    <path d="M7275 4665 c-14 -13 -25 -33 -25 -45 0 -28 43 -70 73 -70 13 0 33
    -11 45 -25 38 -44 82 -24 82 38 0 24 -27 57 -47 57 -5 0 -15 11 -21 25 -11 23
    -47 45 -73 45 -5 0 -21 -11 -34 -25z"/>
    <path d="M10457 4663 c-14 -14 -6 -52 12 -58 14 -4 20 -18 23 -62 4 -49 10
    -61 41 -90 30 -27 45 -33 82 -33 37 0 52 6 81 33 21 19 38 47 43 68 23 101
    -94 184 -183 131 -34 -20 -35 -20 -41 -1 -6 18 -44 27 -58 12z"/>
    <path d="M6755 4647 c-48 -19 -61 -30 -79 -65 -34 -65 -1 -150 71 -187 21 -10
    50 -27 65 -37 32 -22 48 -23 48 -4 0 8 11 27 24 43 33 40 59 103 56 138 -3 39
    -40 88 -83 109 -40 18 -60 19 -102 3z"/>
    <path d="M8720 4645 c-15 -19 3 -47 26 -38 18 7 18 27 0 42 -10 9 -16 8 -26
    -4z"/>
    <path d="M7955 4640 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
    -8 -4 -11 -10z"/>
    <path d="M5090 4625 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
    -15 -7 -15 -15z"/>
    <path d="M8490 4621 c0 -15 6 -21 21 -21 14 0 19 5 17 17 -5 26 -38 29 -38 4z"/>
    <path d="M9734 4614 c-16 -24 -16 -27 3 -52 24 -33 58 -39 84 -16 23 21 24 42
    3 72 -22 31 -69 30 -90 -4z"/>
    <path d="M3790 4610 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M5911 4592 c-15 -28 13 -62 50 -62 25 0 29 4 29 28 0 15 -5 33 -12
    40 -18 18 -56 14 -67 -6z"/>
    <path d="M3637 4593 c-15 -14 -6 -53 13 -63 41 -22 81 12 60 51 -10 19 -59 27
    -73 12z"/>
    <path d="M5240 4592 c0 -5 -11 -14 -25 -20 -14 -7 -28 -19 -31 -27 -8 -21 24
    -55 51 -55 27 0 35 17 35 71 0 28 -4 39 -15 39 -8 0 -15 -4 -15 -8z"/>
    <path d="M8380 4590 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M10374 4589 c-8 -14 11 -33 25 -25 16 10 13 36 -3 36 -8 0 -18 -5
    -22 -11z"/>
    <path d="M4 4565 c-4 -9 0 -23 9 -32 21 -21 49 -9 45 19 -4 28 -45 38 -54 13z"/>
    <path d="M8708 4563 c-49 -8 -69 -57 -38 -91 26 -27 61 -28 85 -2 16 18 17 25
    6 60 -6 22 -15 39 -19 39 -4 -1 -19 -4 -34 -6z"/>
    <path d="M9610 4551 c0 -28 21 -37 36 -16 16 22 10 35 -17 35 -12 0 -19 -7
    -19 -19z"/>
    <path d="M4310 4547 c-8 -7 -20 -21 -26 -32 -8 -14 -20 -19 -39 -17 -35 4 -75
    -23 -75 -50 0 -12 10 -37 23 -55 27 -40 70 -44 113 -10 21 16 42 22 76 22 46
    0 49 -1 59 -35 15 -44 25 -58 62 -77 38 -19 87 -10 126 22 16 14 35 23 43 20
    7 -3 25 4 40 16 25 21 26 24 13 49 -8 15 -25 30 -38 34 -13 3 -29 17 -37 31
    -33 62 -120 78 -169 31 -31 -30 -41 -32 -41 -10 0 8 -13 29 -29 45 -32 31 -74
    38 -101 16z"/>
    <path d="M5502 4548 c-17 -17 -15 -36 7 -48 30 -15 53 -2 49 29 -3 29 -35 40
    -56 19z"/>
    <path d="M8471 4547 c-15 -18 -5 -44 20 -52 26 -9 51 13 47 39 -4 25 -49 34
    -67 13z"/>
    <path d="M8860 4545 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
    -10 -7 -10 -15z"/>
    <path d="M7704 4529 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
    -16 -11z"/>
    <path d="M5024 4499 c-10 -17 23 -46 37 -32 7 7 9 19 6 27 -7 18 -33 21 -43 5z"/>
    <path d="M10424 4496 c-8 -22 15 -76 32 -76 18 0 30 49 17 69 -14 22 -42 26
    -49 7z"/>
    <path d="M11090 4490 c-18 -11 -4 -42 17 -38 10 2 19 10 21 19 4 19 -19 31
    -38 19z"/>
    <path d="M7396 4464 c-19 -18 -20 -34 -5 -68 9 -20 7 -33 -14 -78 -32 -66 -27
    -108 15 -143 42 -36 119 -36 153 1 32 34 41 93 21 133 -9 16 -16 44 -16 60 0
    33 -27 55 -54 45 -11 -4 -16 0 -16 13 0 23 -30 53 -53 53 -8 0 -23 -7 -31 -16z"/>
    <path d="M10260 4460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M8753 4436 c-13 -13 -23 -27 -23 -31 0 -4 -7 -13 -16 -20 -39 -28 19
    -150 94 -195 18 -11 32 -26 32 -34 0 -8 12 -28 26 -45 22 -27 32 -31 76 -31
    42 0 54 5 79 29 26 26 29 36 28 81 -2 58 -15 75 -59 85 -28 6 -30 9 -30 53 0
    62 -35 117 -81 126 -63 12 -103 7 -126 -18z"/>
    <path d="M9890 4450 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M6258 4429 c-34 -13 -42 -36 -33 -99 13 -92 105 -132 173 -75 101 84
    -10 221 -140 174z"/>
    <path d="M8354 4429 c-3 -6 0 -17 7 -25 18 -17 44 -2 36 20 -7 18 -33 21 -43
    5z"/>
    <path d="M10078 4409 c-11 -6 -18 -22 -18 -38 0 -39 28 -70 71 -80 70 -16 93
    33 42 88 -31 34 -69 46 -95 30z"/>
    <path d="M4763 4368 c-96 -25 -97 -161 -1 -189 48 -13 92 -2 117 32 12 16 30
    29 39 29 27 0 42 20 42 55 0 44 -30 70 -70 61 -18 -3 -45 0 -63 7 -21 9 -43
    11 -64 5z"/>
    <path d="M8995 4370 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
    -12 6 -17 -2z"/>
    <path d="M5532 4358 c-27 -27 -6 -68 35 -68 29 0 48 33 33 60 -12 22 -49 27
    -68 8z"/>
    <path d="M10305 4360 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30
    1z"/>
    <path d="M5795 4350 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z"/>
    <path d="M5010 4335 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
    -11 -4 -11 -13z"/>
    <path d="M9032 4333 c2 -10 10 -18 18 -18 22 0 18 29 -3 33 -14 3 -18 -1 -15
    -15z"/>
    <path d="M8262 4323 c2 -10 10 -18 18 -18 8 0 16 8 18 18 2 12 -3 17 -18 17
    -15 0 -20 -5 -18 -17z"/>
    <path d="M11772 4328 c7 -19 38 -22 38 -4 0 10 -9 16 -21 16 -12 0 -19 -5 -17
    -12z"/>
    <path d="M6984 4322 c-20 -13 -23 -41 -7 -57 19 -19 42 -16 57 8 19 30 -20 70
    -50 49z"/>
    <path d="M9112 4309 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
    -15 -5 -13 -11z"/>
    <path d="M8127 4304 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
    17z"/>
    <path d="M9807 4304 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z"/>
    <path d="M9900 4295 c-17 -20 -5 -62 21 -70 24 -8 59 19 59 45 0 37 -55 55
    -80 25z"/>
    <path d="M5087 4238 c-36 -48 -40 -71 -21 -112 35 -71 135 -53 142 25 2 26 -5
    46 -28 77 -37 51 -61 53 -93 10z"/>
    <path d="M7830 4250 c-12 -12 -20 -33 -20 -55 0 -27 -4 -35 -18 -35 -46 0 -53
    -42 -17 -100 53 -83 140 -57 122 37 -4 20 2 36 19 56 31 36 30 62 -1 92 -29
    30 -58 32 -85 5z"/>
    <path d="M11766 4253 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
    <path d="M3590 4240 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
    -16 -4 -16 -10z"/>
    <path d="M5583 4234 c-8 -22 10 -49 32 -49 10 0 22 7 28 16 15 24 1 49 -28 49
    -15 0 -28 -7 -32 -16z"/>
    <path d="M8151 4234 c-25 -18 -28 -65 -5 -88 19 -20 24 -20 52 0 22 15 30 57
    14 80 -13 19 -39 23 -61 8z"/>
    <path d="M5750 4230 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
    -15 -4 -15 -10z"/>
    <path d="M9497 4223 c-12 -12 -7 -50 8 -63 23 -19 65 -4 65 23 0 13 -5 28 -12
    35 -13 13 -50 16 -61 5z"/>
    <path d="M5420 4210 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M9455 4210 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
    -12 -4 -9 -10z"/>
    <path d="M9679 4197 c-26 -17 -35 -31 -37 -57 -6 -57 15 -106 57 -139 73 -58
    173 -35 214 47 10 20 19 26 38 24 15 -1 33 6 43 17 17 19 17 20 -8 36 -16 11
    -26 27 -26 40 0 15 -8 26 -20 30 -33 10 -43 -4 -36 -51 3 -24 3 -44 0 -44 -3
    0 -14 20 -25 45 -12 28 -31 51 -49 60 -44 23 -111 19 -151 -8z"/>
    <path d="M4210 4195 c-17 -20 -5 -62 20 -70 47 -15 82 23 60 65 -13 24 -61 27
    -80 5z"/>
    <path d="M6166 4181 c-7 -11 18 -33 27 -24 4 3 7 12 7 20 0 15 -26 18 -34 4z"/>
    <path d="M7672 4163 c2 -10 10 -18 18 -18 22 0 18 29 -3 33 -14 3 -18 -1 -15
    -15z"/>
    <path d="M9580 4170 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
    -15 -4 -15 -10z"/>
    <path d="M8345 4160 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
    -7 -4 -4 -10z"/>
    <path d="M5494 4146 c-10 -26 4 -48 28 -44 17 2 23 10 23 28 0 18 -6 26 -23
    28 -13 2 -25 -3 -28 -12z"/>
    <path d="M3480 4140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M10325 4126 c-31 -46 14 -116 74 -116 39 0 58 19 65 66 5 39 5 41
    -33 57 -54 24 -87 22 -106 -7z"/>
    <path d="M8214 4125 c-7 -18 3 -35 21 -35 18 0 26 15 19 34 -8 20 -33 21 -40
    1z"/>
    <path d="M8682 4128 c-7 -7 -12 -15 -12 -19 0 -16 31 -38 45 -33 20 8 19 50
    -1 58 -20 8 -18 8 -32 -6z"/>
    <path d="M4397 4112 c-37 -40 -4 -102 53 -102 29 0 70 29 70 50 0 58 -85 94
    -123 52z"/>
    <path d="M9470 4105 c-13 -14 -34 -25 -47 -25 -35 0 -66 -36 -58 -68 14 -56
    44 -66 89 -31 29 23 47 19 27 -5 -7 -8 -11 -27 -9 -43 2 -23 8 -29 35 -31 27
    -3 35 1 44 21 8 17 8 30 1 43 -8 14 -4 23 19 45 34 32 37 57 8 93 -28 35 -77
    36 -109 1z"/>
    <path d="M5572 4104 c-30 -21 -29 -59 3 -80 23 -15 27 -15 50 0 32 21 33 59 3
    80 -12 9 -25 16 -28 16 -3 0 -16 -7 -28 -16z"/>
    <path d="M8301 4110 c-9 -5 -11 -17 -7 -36 7 -28 8 -28 22 -10 24 33 14 64
    -15 46z"/>
    <path d="M7919 4050 c-33 -33 -37 -64 -12 -115 11 -22 24 -35 37 -36 107 -5
    119 -4 124 10 3 7 -3 20 -13 27 -12 9 -16 20 -12 36 4 13 3 37 -1 53 -7 24
    -17 32 -51 42 -42 12 -44 11 -72 -17z"/>
    <path d="M8613 4064 c-3 -8 -1 -20 6 -27 14 -14 47 15 37 32 -10 16 -36 13
    -43 -5z"/>
    <path d="M9104 4045 c-10 -24 12 -42 34 -29 24 16 21 38 -6 42 -13 2 -25 -3
    -28 -13z"/>
    <path d="M5950 4033 c0 -10 -9 -26 -20 -36 -16 -13 -18 -21 -10 -42 5 -14 7
    -38 4 -54 -13 -62 71 -130 137 -111 38 11 79 47 79 69 0 8 9 25 21 37 18 20
    20 28 12 76 -7 43 -12 53 -28 53 -13 0 -21 -8 -23 -23 -3 -18 -7 -22 -20 -16
    -9 5 -40 9 -69 9 -48 0 -51 1 -50 25 1 16 -4 25 -15 28 -12 2 -18 -3 -18 -15z"/>
    <path d="M6870 4040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M10250 4040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M5786 4032 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
    <path d="M8297 4003 c-9 -17 6 -83 18 -83 19 0 30 57 15 75 -14 16 -26 19 -33
    8z"/>
    <path d="M6550 3990 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M7044 3981 c-22 -6 -74 -61 -74 -79 0 -14 28 -23 35 -11 12 18 25 -3
    25 -40 0 -24 9 -52 25 -75 19 -29 22 -38 11 -42 -8 -3 -26 7 -40 21 -23 22
    -33 26 -61 21 -29 -6 -36 -3 -55 23 -13 20 -33 33 -56 37 -25 5 -38 14 -45 31
    -7 19 -15 24 -35 21 -37 -4 -54 -32 -32 -54 14 -15 15 -20 1 -51 -12 -29 -12
    -41 -2 -65 14 -35 54 -68 83 -68 14 0 28 -12 41 -37 13 -23 31 -40 50 -46 51
    -16 58 -27 51 -75 -5 -40 -3 -47 23 -71 32 -30 28 -43 -21 -75 -23 -15 -28
    -26 -28 -57 0 -53 28 -80 83 -80 23 0 63 -2 88 -5 48 -6 93 9 118 40 12 14 18
    12 58 -16 38 -27 55 -33 114 -36 67 -4 69 -5 69 -31 0 -37 66 -91 110 -91 42
    0 90 47 90 88 0 36 -31 78 -65 86 -30 8 -31 15 -4 60 31 53 28 96 -9 156 -24
    38 -30 56 -22 65 12 15 4 41 -15 49 -9 3 -12 17 -9 43 7 51 -25 103 -61 103
    -38 0 -105 -41 -112 -69 -5 -19 0 -30 22 -48 15 -13 26 -25 24 -27 -2 -2 -21
    -6 -41 -10 -34 -6 -38 -5 -38 14 0 11 -9 40 -21 64 -11 24 -21 56 -23 71 -2
    15 -10 32 -19 37 -13 7 -14 14 -7 34 24 62 2 104 -89 172 -31 24 -95 34 -137
    23z"/>
    <path d="M11835 3980 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
    -2 0 -7 -4 -10 -10z"/>
    <path d="M4542 3949 c-41 -30 -46 -42 -43 -99 4 -53 88 -93 150 -72 26 9 31 8
    36 -9 7 -20 42 -39 73 -39 9 0 29 12 45 25 27 24 27 25 11 59 -17 35 -49 56
    -84 56 -14 0 -20 7 -20 23 0 35 -27 65 -68 77 -52 14 -53 14 -100 -21z"/>
    <path d="M8144 3969 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
    -16 -11z"/>
    <path d="M10100 3970 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M6896 3963 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
    <path d="M8733 3939 c-18 -11 -30 -26 -27 -33 7 -18 74 -28 91 -14 19 16 16
    46 -6 58 -25 13 -20 13 -58 -11z"/>
    <path d="M6413 3934 c-7 -18 17 -38 31 -24 11 11 1 40 -14 40 -6 0 -13 -7 -17
    -16z"/>
    <path d="M6932 3939 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
    -15 -5 -13 -11z"/>
    <path d="M11698 3945 c-10 -10 4 -54 19 -59 26 -10 32 3 16 34 -14 29 -24 36
    -35 25z"/>
    <path d="M8537 3934 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
    17z"/>
    <path d="M9313 3919 c-42 -26 -41 -38 3 -66 41 -27 53 -28 78 -10 38 28 13 97
    -33 97 -9 0 -31 -10 -48 -21z"/>
    <path d="M10188 3926 c-10 -10 -12 -30 -8 -76 5 -52 11 -67 33 -86 75 -64 160
    -21 143 72 -8 39 -39 68 -96 88 -55 19 -55 19 -72 2z"/>
    <path d="M3739 3917 c-39 -18 -59 -37 -59 -58 0 -10 -9 -24 -19 -31 -58 -41
    -17 -175 66 -217 37 -19 122 -13 161 11 l35 21 -5 -69 c-4 -52 0 -79 13 -109
    10 -22 20 -52 23 -66 8 -37 55 -56 96 -39 64 26 130 109 130 162 0 70 -131
    188 -195 176 -22 -5 -23 -3 -19 51 4 49 1 61 -23 96 -16 21 -42 47 -59 57 -40
    23 -112 31 -145 15z"/>
    <path d="M6867 3923 c-9 -9 22 -43 40 -43 20 0 16 28 -6 40 -23 12 -26 12 -34
    3z"/>
    <path d="M3070 3910 c-8 -5 -11 -12 -7 -16 4 -4 13 -2 19 4 15 15 7 24 -12 12z"/>
    <path d="M5830 3910 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M7487 3903 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"/>
    <path d="M7720 3895 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
    -15 -7 -15 -15z"/>
    <path d="M9984 3899 c-3 -6 -1 -16 4 -21 13 -13 45 8 36 23 -7 12 -31 11 -40
    -2z"/>
    <path d="M8309 3876 c-8 -18 -7 -30 5 -54 9 -17 16 -37 16 -45 0 -8 7 -20 15
    -27 8 -7 17 -28 20 -49 14 -80 17 -91 44 -122 23 -26 35 -31 69 -31 86 1 114
    45 107 168 -5 84 -13 103 -41 104 -6 0 -17 -7 -24 -15 -7 -8 -19 -15 -27 -15
    -28 0 -73 34 -73 55 -1 35 -24 55 -64 55 -29 0 -39 -5 -47 -24z"/>
    <path d="M6934 3875 c-7 -18 3 -35 21 -35 21 0 28 19 15 35 -16 19 -29 19 -36
    0z"/>
    <path d="M6604 3865 c-14 -34 13 -56 45 -36 10 7 11 13 2 30 -14 24 -39 28
    -47 6z"/>
    <path d="M8644 3869 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
    -16 -11z"/>
    <path d="M6285 3862 c-54 -18 -91 -74 -80 -121 6 -27 4 -29 -35 -34 -62 -8
    -90 -39 -90 -98 0 -66 29 -99 93 -106 43 -5 49 -3 82 30 28 28 35 43 35 74 0
    36 2 39 43 53 86 30 103 137 28 187 -35 24 -43 25 -76 15z"/>
    <path d="M10030 3850 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M5753 3840 c-36 -15 -56 -55 -49 -95 5 -26 3 -35 -7 -35 -21 0 -59
    -46 -55 -67 2 -10 13 -19 26 -21 27 -4 72 23 72 43 0 10 11 13 40 12 29 -1 46
    5 65 23 64 60 -10 174 -92 140z"/>
    <path d="M4042 3831 c-8 -4 -12 -19 -10 -32 2 -19 9 -24 33 -24 33 0 45 25 25
    50 -14 17 -29 19 -48 6z"/>
    <path d="M8052 3823 c4 -23 21 -26 34 -5 10 15 8 18 -13 20 -18 1 -24 -2 -21
    -15z"/>
    <path d="M4372 3808 c-21 -21 -14 -57 14 -70 31 -14 57 -1 62 31 7 42 -46 69
    -76 39z"/>
    <path d="M1175 3801 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33 14z"/>
    <path d="M9044 3774 c-8 -31 6 -47 36 -42 53 7 47 68 -6 68 -17 0 -25 -7 -30
    -26z"/>
    <path d="M9591 3783 c-12 -10 -22 -27 -22 -38 1 -11 1 -44 1 -73 1 -43 6 -59
    25 -78 31 -31 69 -31 101 2 22 21 25 31 20 62 -4 20 -9 54 -12 75 -10 61 -68
    87 -113 50z"/>
    <path d="M11674 3787 c-8 -22 14 -67 37 -76 61 -22 92 24 46 67 -26 24 -75 29
    -83 9z"/>
    <path d="M8020 3780 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
    -15 -4 -15 -10z"/>
    <path d="M9750 3780 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
    -15 -4 -15 -10z"/>
    <path d="M10624 3765 c-9 -23 3 -45 25 -45 23 0 35 28 20 46 -15 18 -38 18
    -45 -1z"/>
    <path d="M8237 3764 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z"/>
    <path d="M5375 3718 c-37 -44 -30 -100 16 -142 27 -24 38 -27 72 -23 84 11
    119 99 66 166 -21 26 -30 30 -76 30 -46 1 -54 -2 -78 -31z"/>
    <path d="M6665 3740 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z"/>
    <path d="M7598 3694 c-20 -10 -28 -22 -28 -43 0 -32 17 -50 81 -79 56 -27 80
    -28 110 -3 30 24 32 83 4 110 -28 26 -130 36 -167 15z"/>
    <path d="M4850 3681 c-13 -26 1 -57 30 -66 30 -10 52 13 48 49 -3 27 -7 31
    -35 34 -24 2 -34 -2 -43 -17z"/>
    <path d="M11650 3660 c-57 -57 12 -150 112 -150 78 0 110 52 67 111 -42 59
    -138 80 -179 39z"/>
    <path d="M9210 3655 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
    -13z"/>
    <path d="M7849 3634 c-17 -21 0 -49 32 -49 27 0 37 28 19 50 -16 19 -34 19
    -51 -1z"/>
    <path d="M9290 3606 c0 -8 5 -18 10 -21 13 -8 42 14 34 26 -9 15 -44 10 -44
    -5z"/>
    <path d="M5725 3591 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
    -9z"/>
    <path d="M9575 3560 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
    -12 -4 -9 -10z"/>
    <path d="M7983 3504 c-4 -11 1 -22 12 -31 16 -11 21 -11 33 1 8 8 12 22 9 30
    -8 21 -46 21 -54 0z"/>
    <path d="M12090 3510 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
    0 -9 -4 -9 -10z"/>
    <path d="M2905 3491 c-119 -20 -196 -143 -171 -274 9 -48 67 -104 144 -138
    l65 -30 44 21 c23 11 47 20 52 20 21 0 69 35 88 64 31 48 46 126 34 173 -14
    50 -69 121 -107 138 -36 16 -95 35 -103 34 -3 -1 -24 -4 -46 -8z"/>
    <path d="M7712 3478 c2 -17 9 -22 23 -20 28 5 30 9 15 27 -19 23 -42 19 -38
    -7z"/>
    <path d="M8281 3483 c-24 -10 -70 -67 -71 -89 0 -31 44 -108 64 -112 33 -7 66
    8 72 32 3 14 14 32 25 42 21 19 25 61 8 98 -14 31 -59 44 -98 29z"/>
    <path d="M8957 3476 c-7 -17 18 -40 33 -31 5 3 10 15 10 26 0 23 -35 27 -43 5z"/>
    <path d="M8830 3440 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M6352 3420 c-71 -44 -69 -141 5 -179 65 -33 140 9 150 85 4 32 0 44
    -24 71 -37 42 -86 51 -131 23z"/>
    <path d="M10002 3418 c-15 -15 -16 -60 0 -76 18 -18 53 -14 74 9 17 19 17 23
    3 47 -16 30 -57 40 -77 20z"/>
    <path d="M9283 3413 c-7 -3 -13 -13 -13 -23 0 -19 17 -30 38 -23 6 3 12 13 12
    23 0 19 -17 30 -37 23z"/>
    <path d="M7834 3395 c-4 -8 -2 -21 4 -28 6 -6 12 -23 14 -37 2 -20 9 -25 33
    -25 42 0 43 25 2 69 -38 40 -45 43 -53 21z"/>
    <path d="M9498 3399 c-44 -10 -104 -46 -134 -80 -11 -13 -29 -46 -39 -72 -38
    -104 26 -239 136 -286 45 -20 65 -23 129 -19 46 3 86 12 105 23 34 20 105 136
    105 171 0 17 7 24 30 29 28 6 43 25 19 25 -6 0 -8 8 -4 19 3 10 -1 24 -10 31
    -8 7 -13 20 -10 29 3 10 -6 22 -25 33 -30 17 -39 41 -20 53 6 3 10 11 10 16 0
    6 -12 1 -26 -10 -33 -26 -46 -26 -69 -1 -19 21 -104 51 -139 49 -12 -1 -38 -5
    -58 -10z"/>
    <path d="M8014 3385 c-9 -23 4 -47 23 -43 22 4 30 38 11 49 -19 13 -28 11 -34
    -6z"/>
    <path d="M8600 3365 c-15 -18 -6 -45 13 -45 8 0 20 7 27 15 10 12 10 18 0 30
    -16 19 -24 19 -40 0z"/>
    <path d="M5215 3346 c-22 -16 -16 -44 11 -63 19 -12 24 -12 38 3 36 35 -9 90
    -49 60z"/>
    <path d="M5700 3344 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
    -10 -7 -10 -16z"/>
    <path d="M7782 3339 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
    -15 -5 -13 -11z"/>
    <path d="M8653 3324 c-13 -34 35 -70 60 -45 7 7 5 18 -7 35 -19 30 -44 34 -53
    10z"/>
    <path d="M8007 3314 c-12 -12 -8 -41 8 -54 20 -17 47 5 43 34 -3 21 -37 34
    -51 20z"/>
    <path d="M9912 3295 c-57 -48 -44 -128 26 -159 46 -21 77 -12 115 34 32 38 32
    40 7 89 -13 26 -30 43 -51 50 -47 16 -64 14 -97 -14z"/>
    <path d="M8937 3304 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
    15z"/>
    <path d="M6727 3285 c-8 -18 11 -31 25 -17 11 11 3 32 -12 32 -4 0 -10 -7 -13
    -15z"/>
    <path d="M7695 3275 c-30 -29 -32 -68 -5 -95 32 -32 67 -36 96 -9 32 30 32 72
    -1 104 -31 32 -59 32 -90 0z"/>
    <path d="M2362 3248 c-7 -7 -12 -21 -12 -33 0 -29 19 -45 52 -45 36 0 56 36
    39 69 -13 23 -59 29 -79 9z"/>
    <path d="M5766 3244 c-23 -22 -20 -41 8 -59 48 -32 90 20 48 58 -23 21 -36 21
    -56 1z"/>
    <path d="M6557 3246 c-14 -35 23 -67 50 -45 8 7 13 22 11 33 -4 26 -52 35 -61
    12z"/>
    <path d="M8060 3245 c-26 -32 13 -81 48 -59 34 22 22 74 -18 74 -10 0 -23 -7
    -30 -15z"/>
    <path d="M11282 3249 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
    0 -15 -5 -13 -11z"/>
    <path d="M8470 3230 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M6288 3215 c-30 -17 -52 -71 -43 -108 12 -48 54 -72 117 -68 9 1 31
    22 49 47 l31 46 -18 35 c-33 62 -79 78 -136 48z"/>
    <path d="M677 3203 c-15 -14 -6 -24 20 -21 34 4 43 28 11 28 -14 0 -28 -3 -31
    -7z"/>
    <path d="M7977 3203 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
    -9 2 -12 -2z"/>
    <path d="M8180 3195 c-15 -18 -5 -35 21 -35 12 0 19 7 19 19 0 24 -25 34 -40
    16z"/>
    <path d="M8985 3180 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
    7z"/>
    <path d="M8010 3167 c0 -7 -9 -18 -20 -25 -28 -17 -25 -50 5 -64 18 -8 28 -8
    39 1 22 19 25 59 6 81 -20 23 -30 25 -30 7z"/>
    <path d="M6664 3149 c-11 -6 -28 -23 -37 -39 -54 -90 45 -185 142 -136 55 29
    64 111 16 156 -26 25 -90 35 -121 19z"/>
    <path d="M8924 3146 c-10 -26 4 -48 28 -44 17 2 23 10 23 28 0 18 -6 26 -23
    28 -13 2 -25 -3 -28 -12z"/>
    <path d="M9175 3141 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33 14z"/>
    <path d="M12150 3120 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M7715 3096 c-59 -59 -36 -147 47 -177 l47 -17 21 39 c22 44 26 115 7
    140 -33 43 -87 50 -122 15z"/>
    <path d="M10572 3108 c-30 -30 9 -69 44 -43 15 11 17 17 8 32 -14 23 -36 27
    -52 11z"/>
    <path d="M9990 3070 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
    -13 2 -18 -3 -18 -18z"/>
    <path d="M8374 3056 c-10 -26 1 -51 23 -54 30 -4 49 31 27 52 -19 20 -43 21
    -50 2z"/>
    <path d="M10444 3029 c-8 -14 11 -33 25 -25 16 10 13 36 -3 36 -8 0 -18 -5
    -22 -11z"/>
    <path d="M4641 3006 c-30 -32 -25 -75 11 -95 22 -13 30 -13 51 -2 51 27 52 92
    2 111 -36 14 -39 13 -64 -14z"/>
    <path d="M7327 3003 c-14 -13 -7 -36 22 -72 15 -20 31 -46 35 -59 4 -12 20
    -29 36 -37 97 -50 178 55 110 143 -21 27 -30 31 -55 27 -16 -3 -44 -8 -61 -11
    -18 -4 -35 -1 -38 5 -8 12 -39 15 -49 4z"/>
    <path d="M10734 3000 c-30 -12 -56 -55 -48 -84 7 -31 54 -52 82 -37 27 14 45
    67 32 92 -14 26 -41 38 -66 29z"/>
    <path d="M8127 2993 c-15 -14 -7 -43 12 -43 26 0 36 17 21 35 -14 16 -23 19
    -33 8z"/>
    <path d="M8735 2970 c-23 -25 -14 -61 28 -106 l32 -35 34 26 c19 14 41 25 49
    25 13 0 13 3 0 18 -9 9 -19 27 -23 40 -7 24 -52 52 -83 52 -10 0 -27 -9 -37
    -20z"/>
    <path d="M3087 2963 c-45 -51 -97 -201 -66 -191 15 4 84 136 95 181 7 31 -5
    35 -29 10z"/>
    <path d="M9738 2954 c-20 -42 18 -82 65 -70 23 5 27 12 27 45 0 41 -11 51 -56
    51 -17 0 -28 -8 -36 -26z"/>
    <path d="M9075 2961 c-3 -5 -1 -12 5 -16 12 -7 30 2 30 16 0 12 -27 12 -35 0z"/>
    <path d="M5365 2950 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
    <path d="M7950 2951 c0 -5 -9 -11 -21 -14 -22 -6 -22 -28 0 -35 7 -2 15 5 18
    17 3 12 9 21 14 21 5 0 9 5 9 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -9z"/>
    <path d="M4411 2915 c-20 -23 -39 -35 -56 -35 -26 0 -65 -31 -65 -53 0 -7 7
    -23 16 -35 13 -19 24 -23 54 -21 22 2 45 -3 55 -11 12 -10 37 -14 69 -13 28 1
    66 2 85 2 18 1 40 6 47 12 21 17 17 66 -6 79 -11 6 -23 22 -26 35 -10 39 -59
    75 -104 75 -31 0 -44 -6 -69 -35z"/>
    <path d="M5007 2926 c-3 -8 0 -19 7 -25 18 -15 48 -3 44 18 -4 21 -44 27 -51
    7z"/>
    <path d="M8934 2915 c-4 -8 -1 -22 6 -30 26 -32 78 -8 59 27 -12 22 -57 24
    -65 3z"/>
    <path d="M9127 2914 c-13 -14 -7 -52 10 -58 25 -10 51 20 37 42 -12 19 -36 27
    -47 16z"/>
    <path d="M5776 2868 c-23 -45 -20 -75 9 -94 33 -22 77 -7 90 30 9 26 6 31 -30
    62 -22 19 -43 34 -47 34 -3 0 -14 -14 -22 -32z"/>
    <path d="M9007 2873 c-12 -11 -7 -33 8 -39 20 -8 45 13 38 32 -5 14 -34 19
    -46 7z"/>
    <path d="M7095 2860 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
    -12 6 -17 -2z"/>
    <path d="M10620 2855 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
    0 -10 -7 -10 -15z"/>
    <path d="M7849 2834 c-16 -19 -7 -49 16 -51 24 -3 68 32 63 49 -6 17 -65 19
    -79 2z"/>
    <path d="M8725 2820 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
    -12 6 -17 -2z"/>
    <path d="M5621 2806 c-28 -33 22 -83 55 -55 8 7 14 24 12 38 -2 20 -9 27 -29
    29 -14 2 -31 -4 -38 -12z"/>
    <path d="M6479 2774 c-15 -18 -2 -47 24 -52 25 -5 40 33 21 52 -19 20 -28 20
    -45 0z"/>
    <path d="M6037 2772 c-13 -3 -17 -11 -15 -30 2 -21 9 -28 29 -30 28 -3 43 16
    33 42 -7 17 -23 24 -47 18z"/>
    <path d="M9637 2758 c-47 -37 -16 -118 47 -118 42 0 64 18 72 56 5 25 1 37
    -15 53 -24 24 -78 28 -104 9z"/>
    <path d="M3787 2728 c-9 -7 -19 -26 -23 -43 -15 -82 113 -106 131 -24 13 57
    -60 102 -108 67z"/>
    <path d="M9387 2718 c-6 -18 11 -48 27 -48 8 0 19 7 26 15 10 12 10 18 0 30
    -15 18 -47 20 -53 3z"/>
    <path d="M5520 2695 c-10 -12 -10 -18 0 -30 16 -20 36 -19 44 1 6 16 -9 44
    -24 44 -4 0 -13 -7 -20 -15z"/>
    <path d="M8796 2702 c-9 -14 12 -35 28 -29 23 9 20 37 -3 37 -11 0 -22 -4 -25
    -8z"/>
    <path d="M5735 2686 c-33 -14 -55 -52 -55 -92 0 -37 59 -94 97 -94 28 0 113
    51 113 68 -1 26 -32 90 -54 109 -30 26 -57 28 -101 9z"/>
    <path d="M11005 2689 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
    <path d="M6100 2674 c-10 -11 -10 -17 0 -28 15 -19 40 -10 40 14 0 24 -25 33
    -40 14z"/>
    <path d="M7887 2664 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
    17z"/>
    <path d="M8417 2644 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z"/>
    <path d="M5624 2629 c-8 -14 11 -33 25 -25 6 4 11 14 11 22 0 16 -26 19 -36 3z"/>
    <path d="M3634 2605 c-40 -61 44 -127 96 -75 41 41 13 100 -48 100 -22 0 -36
    -7 -48 -25z"/>
    <path d="M10353 2620 c-29 -12 -45 -44 -37 -75 5 -20 3 -25 -13 -25 -26 0 -61
    -39 -69 -77 -13 -65 58 -135 122 -119 31 8 104 83 104 107 0 17 -32 55 -52 61
    -9 3 -6 12 10 33 51 64 10 125 -65 95z"/>
    <path d="M3431 2594 c-26 -33 -26 -35 0 -68 28 -36 77 -33 98 6 14 25 14 29
    -5 55 -29 39 -66 41 -93 7z"/>
    <path d="M10724 2605 c-8 -20 4 -35 27 -35 21 0 25 20 7 38 -16 16 -27 15 -34
    -3z"/>
    <path d="M8670 2600 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M8560 2586 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z"/>
    <path d="M5485 2558 c-78 -43 -113 -129 -84 -205 16 -43 79 -102 121 -113 73
    -20 155 10 202 73 15 20 20 42 20 82 -2 104 -78 175 -186 175 -29 0 -62 -6
    -73 -12z"/>
    <path d="M7754 2556 c-10 -26 1 -51 24 -54 14 -2 24 3 28 15 3 10 10 26 15 36
    6 11 6 17 -1 17 -5 0 -10 -5 -10 -12 0 -9 -3 -9 -12 0 -16 16 -37 15 -44 -2z"/>
    <path d="M8867 2554 c-4 -4 -7 -17 -7 -30 0 -23 17 -34 51 -34 17 0 20 6 17
    33 -2 26 -8 33 -28 35 -14 2 -29 0 -33 -4z"/>
    <path d="M7277 2523 c-14 -13 -7 -43 12 -53 26 -14 29 -13 41 11 9 15 8 24 -1
    35 -12 15 -41 19 -52 7z"/>
    <path d="M8654 2485 c-4 -8 -10 -13 -14 -10 -14 9 -23 -16 -10 -28 9 -9 15 -7
    26 7 8 11 14 16 14 12 0 -5 7 -3 15 4 8 7 12 16 9 21 -8 14 -34 10 -40 -6z"/>
    <path d="M10680 2485 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
    0 -10 -7 -10 -15z"/>
    <path d="M11852 2481 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z"/>
    <path d="M2962 2463 c-7 -3 -18 -16 -23 -30 -13 -36 12 -73 49 -73 35 0 52 16
    52 50 0 43 -39 69 -78 53z"/>
    <path d="M8853 2444 c-8 -21 13 -42 28 -27 13 13 5 43 -11 43 -6 0 -13 -7 -17
    -16z"/>
    <path d="M3168 2425 c-10 -22 -9 -29 10 -47 18 -19 25 -20 47 -10 16 8 25 20
    25 35 0 50 -62 66 -82 22z"/>
    <path d="M9303 2435 c-12 -8 -27 -30 -33 -49 -23 -72 47 -147 122 -132 18 4
    44 8 58 10 34 5 34 4 20 21 -6 8 -9 23 -5 35 25 79 -93 163 -162 115z"/>
    <path d="M11840 2440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M9917 2433 c-4 -3 -7 -13 -7 -20 0 -22 74 -13 78 10 3 13 -4 17 -31
    17 -18 0 -37 -3 -40 -7z"/>
    <path d="M2384 2411 c-22 -10 -56 -36 -74 -58 -37 -44 -75 -149 -61 -167 5 -6
    12 -27 16 -47 8 -45 79 -125 120 -135 17 -4 35 -11 41 -15 13 -11 132 0 140
    12 3 5 13 9 23 9 24 0 74 56 100 111 20 42 21 53 12 110 -13 81 -50 133 -121
    171 -63 34 -134 37 -196 9z"/>
    <path d="M8890 2389 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
    -5 -10 -11z"/>
    <path d="M8697 2383 c-4 -3 -7 -12 -7 -20 0 -15 26 -18 34 -4 7 11 -18 33 -27
    24z"/>
    <path d="M8177 2363 c-12 -11 -7 -33 8 -39 20 -8 45 13 38 32 -5 14 -34 19
    -46 7z"/>
    <path d="M9620 2360 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
    -15 -4 -15 -10z"/>
    <path d="M9661 2346 c-16 -20 -3 -46 24 -46 25 0 38 21 29 45 -8 19 -38 19
    -53 1z"/>
    <path d="M5846 2327 c-11 -8 -17 -23 -14 -38 2 -20 8 -24 38 -24 28 0 36 4 38
    21 3 20 -20 54 -37 54 -4 0 -15 -6 -25 -13z"/>
    <path d="M6667 2317 c-8 -21 8 -39 29 -35 27 5 25 42 -2 46 -12 2 -24 -3 -27
    -11z"/>
    <path d="M11292 2323 c-19 -7 -42 -58 -42 -91 0 -40 38 -92 85 -119 47 -27
    127 -30 165 -8 14 8 33 18 43 21 23 8 22 20 -4 42 -11 9 -29 33 -40 52 -28 52
    -61 85 -99 98 -34 12 -85 15 -108 5z"/>
    <path d="M6205 2300 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
    7z"/>
    <path d="M7470 2290 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M8477 2288 c-8 -23 13 -48 39 -48 29 0 42 24 24 45 -15 18 -57 20
    -63 3z"/>
    <path d="M9816 2275 c-58 -54 -22 -165 60 -181 98 -18 165 106 95 177 -40 39
    -114 42 -155 4z"/>
    <path d="M6765 2281 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
    -9z"/>
    <path d="M10620 2280 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M8925 2250 c-4 -7 0 -19 9 -28 15 -15 18 -15 26 -2 5 8 8 20 7 25 -5
    15 -34 18 -42 5z"/>
    <path d="M10635 2250 c-7 -12 11 -40 26 -40 14 0 10 26 -5 39 -10 9 -16 9 -21
    1z"/>
    <path d="M7776 2234 c-21 -20 -20 -73 0 -102 41 -59 114 -43 114 24 0 26 -8
    43 -29 65 -33 32 -62 37 -85 13z"/>
    <path d="M8776 2228 c-9 -33 -8 -36 14 -58 22 -22 69 -27 88 -8 34 34 -2 88
    -60 88 -29 0 -37 -5 -42 -22z"/>
    <path d="M6943 2220 c-48 -19 -60 -104 -20 -144 21 -20 23 -31 19 -82 -5 -72
    15 -104 78 -123 73 -22 135 13 146 81 8 46 -17 94 -59 114 -31 15 -33 18 -33
    71 1 46 -3 58 -23 74 -24 20 -71 24 -108 9z"/>
    <path d="M8373 2200 c-10 -11 -28 -20 -40 -20 -15 0 -27 -9 -35 -25 -16 -35
    13 -67 56 -63 26 3 31 7 28 28 -1 18 4 28 20 35 30 13 34 41 9 55 -17 9 -24 7
    -38 -10z"/>
    <path d="M6510 2181 c0 -24 25 -34 40 -16 15 18 5 35 -21 35 -12 0 -19 -7 -19
    -19z"/>
    <path d="M10624 2175 c-7 -17 21 -65 36 -65 6 0 10 -19 10 -43 0 -30 9 -56 28
    -88 36 -55 58 -74 91 -75 14 0 34 -4 44 -9 15 -6 18 -4 15 11 -2 14 2 18 18
    16 l21 -3 -20 62 c-23 71 -77 133 -126 144 -17 4 -31 11 -31 16 0 9 -59 49
    -73 49 -4 0 -10 -7 -13 -15z"/>
    <path d="M11602 2179 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17
    -8 3 -12 -1 -9 -9z"/>
    <path d="M11644 2175 c-10 -25 4 -45 30 -45 29 0 40 24 20 45 -20 19 -43 19
    -50 0z"/>
    <path d="M8640 2160 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
    -13 0 -20 -7 -20 -20z"/>
    <path d="M11560 2166 c0 -15 24 -28 34 -19 7 7 -13 33 -25 33 -5 0 -9 -6 -9
    -14z"/>
    <path d="M7963 2129 c-16 -16 -5 -39 18 -39 22 0 25 22 6 39 -10 8 -16 8 -24
    0z"/>
    <path d="M3909 2037 c-52 -35 -102 -66 -111 -70 -10 -3 -18 -11 -18 -17 0 -14
    7 -13 38 9 15 10 66 44 115 76 48 31 87 58 87 61 0 12 -25 -1 -111 -59z"/>
    <path d="M5657 2072 c-36 -40 -9 -112 42 -112 35 0 61 28 61 67 0 55 -67 85
    -103 45z"/>
    <path d="M6725 2060 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
    7z"/>
    <path d="M8224 2045 c-4 -8 -15 -15 -25 -15 -91 0 -157 -105 -114 -180 8 -14
    14 -37 15 -52 0 -31 26 -49 55 -38 11 4 28 3 38 -2 45 -23 151 30 153 77 1 12
    4 27 7 32 9 15 -12 72 -40 111 -14 18 -22 36 -19 39 3 3 1 14 -5 24 -12 23
    -57 25 -65 4z"/>
    <path d="M6874 2025 c-4 -9 -2 -21 4 -27 15 -15 44 -1 40 19 -4 23 -36 29 -44
    8z"/>
    <path d="M7334 1975 c-84 -35 -54 -175 38 -175 43 0 78 40 78 87 0 71 -56 113
    -116 88z"/>
    <path d="M3100 1924 c-14 -36 -12 -64 3 -64 14 0 40 67 31 82 -10 15 -25 8
    -34 -18z"/>
    <path d="M6876 1931 c-11 -18 6 -41 30 -41 19 0 24 5 24 25 0 20 -5 25 -24 25
    -14 0 -27 -4 -30 -9z"/>
    <path d="M8685 1918 c-54 -47 -27 -129 53 -157 48 -17 81 -7 107 33 34 52 -20
    130 -98 143 -26 4 -40 0 -62 -19z"/>
    <path d="M6787 1903 c-11 -10 -8 -41 4 -49 6 -3 18 1 27 9 13 13 13 19 2 31
    -14 17 -23 20 -33 9z"/>
    <path d="M9817 1884 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z"/>
    <path d="M10033 1879 c-20 -21 -15 -47 12 -65 21 -14 30 -14 55 -4 27 11 67
    57 58 66 -2 2 -29 6 -59 10 -38 4 -58 2 -66 -7z"/>
    <path d="M6687 1864 c-15 -16 -7 -53 14 -59 30 -10 58 19 43 43 -12 18 -45 28
    -57 16z"/>
    <path d="M10083 1764 c-7 -20 22 -45 44 -37 8 3 13 15 11 27 -4 27 -45 35 -55
    10z"/>
    <path d="M9150 1750 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
    -15 -4 -15 -10z"/>
    <path d="M9458 1754 c-11 -10 3 -34 19 -34 8 0 13 7 11 17 -3 18 -20 27 -30
    17z"/>
    <path d="M10212 1748 c5 -15 28 -18 29 -3 0 6 -7 12 -17 13 -10 3 -15 -1 -12
    -10z"/>
    <path d="M6864 1685 c-8 -21 2 -45 19 -45 21 0 38 25 31 44 -8 20 -43 21 -50
    1z"/>
    <path d="M8140 1655 c0 -28 29 -48 50 -35 11 7 12 14 2 34 -16 35 -52 36 -52
    1z"/>
    <path d="M8820 1610 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
    -15 -4 -15 -10z"/>
    <path d="M9492 1588 c4 -30 48 -39 48 -10 0 18 -18 32 -42 32 -5 0 -8 -10 -6
    -22z"/>
    <path d="M6102 1578 c-34 -34 14 -102 56 -79 48 26 31 90 -23 91 -12 0 -26 -5
    -33 -12z"/>
    <path d="M9927 1523 c-10 -15 10 -35 29 -31 26 5 24 32 -3 36 -11 2 -23 -1
    -26 -5z"/>
    <path d="M8870 1480 c0 -12 -7 -20 -16 -20 -10 0 -14 -7 -12 -22 2 -15 10 -23
    23 -23 14 0 20 6 19 23 0 12 3 22 8 22 4 0 8 9 8 20 0 11 -7 20 -15 20 -8 0
    -15 -9 -15 -20z"/>
    <path d="M8205 1471 c-9 -15 13 -51 31 -51 11 0 14 8 12 27 -3 28 -30 43 -43
    24z"/>
    <path d="M6625 1445 c-14 -13 -25 -36 -25 -50 0 -31 41 -75 70 -75 79 0 105
    106 34 139 -37 16 -52 14 -79 -14z"/>
    <path d="M8297 1434 c-10 -10 12 -44 28 -44 18 0 20 21 3 38 -13 13 -22 15
    -31 6z"/>
    <path d="M2683 1409 c-105 -30 -91 -180 19 -204 36 -8 77 12 104 52 18 27 20
    40 14 68 -10 45 -26 65 -67 82 -18 7 -34 13 -35 12 -2 0 -17 -5 -35 -10z"/>
    <path d="M9746 1413 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
    <path d="M9850 1371 c0 -10 47 -21 54 -13 3 3 3 9 0 13 -7 12 -54 12 -54 0z"/>
    <path d="M8280 1345 c-8 -10 -11 -32 -7 -62 4 -38 12 -54 41 -80 48 -44 73
    -43 82 3 3 19 4 52 1 72 -11 64 -85 106 -117 67z"/>
    <path d="M9539 1333 c-28 -73 -4 -143 56 -169 31 -13 33 -16 28 -48 -3 -19 -3
    -47 1 -61 7 -27 67 -100 95 -115 25 -13 133 15 154 41 24 30 23 105 -3 144
    -26 40 -25 63 2 67 29 4 35 34 12 59 -32 35 -83 13 -71 -31 5 -18 0 -20 -43
    -20 -48 0 -49 1 -56 35 -9 52 -97 125 -150 125 -8 0 -19 -12 -25 -27z"/>
    <path d="M6338 1179 c-26 -15 -24 -75 3 -89 31 -17 62 -8 77 23 25 53 -28 97
    -80 66z"/>
    <path d="M9113 1068 c-4 -7 -9 -26 -10 -43 -1 -16 -5 -46 -8 -66 -17 -94 81
    -165 158 -115 23 16 27 25 27 68 0 60 -19 92 -68 111 -20 8 -46 24 -56 36 -22
    24 -32 26 -43 9z"/>
    <path d="M2485 1010 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
    <path d="M3060 969 c0 -14 5 -19 17 -17 26 5 29 38 4 38 -15 0 -21 -6 -21 -21z"/>
    <path d="M3910 929 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
    -5 -10 -11z"/>
    <path d="M6222 574 c-43 -30 -18 -104 36 -104 50 0 72 75 30 104 -12 9 -27 16
    -33 16 -6 0 -21 -7 -33 -16z"/>
    <path d="M10010 365 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
    -10 -7 -10 -15z"/>
    <path d="M9934 177 c-14 -30 -14 -37 0 -70 17 -41 59 -68 78 -49 6 6 13 32 15
    59 4 39 1 52 -17 71 -30 32 -57 28 -76 -11z"/>
    <path d="M10110 43 c0 -20 18 -43 33 -43 4 0 7 12 5 27 -4 32 -38 46 -38 16z"/>
    </g>
    </svg>
    
);

export default InkSplatter;
