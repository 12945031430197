import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const images = [
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-1.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-2.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-3.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-4.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-5.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-6.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-7.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-8.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-9.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-10.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-11.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-12.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-13.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-14.jpg",
  "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-15.jpg",
];

export default function Gallery() {
  const plane1 = useRef(null);
  const plane2 = useRef(null);
  const plane3 = useRef(null);
  const plane4 = useRef(null);
  const plane5 = useRef(null);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  // Function to handle mouse movement for parallax effect
  const manageMouseMove = (e) => {
    const x = e.clientX / window.innerWidth - 0.5;
    const y = e.clientY / window.innerHeight - 0.5;
    setMousePos({ x, y });
  };

  useEffect(() => {
    const planes = [plane1.current, plane2.current, plane3.current, plane4.current, plane5.current];

    // Scroll animations for each plane
    planes.forEach((plane) => {
      gsap.fromTo(
        plane,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power4.out",
          scrollTrigger: {
            trigger: plane,
            start: "top 80%",
            end: "top 30%",
            scrub: true,
          },
        }
      );
    });

    gsap.fromTo(
      ".center-text",
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        ease: "power4.out",
        scrollTrigger: {
          trigger: ".center-text",
          start: "top 85%",
        },
      }
    );
  }, []);

  useEffect(() => {
    gsap.to(plane1.current, { x: mousePos.x * 40, y: mousePos.y * 50 });
    gsap.to(plane2.current, { x: mousePos.x * -40, y: mousePos.y * -50 });
    gsap.to(plane3.current, { x: mousePos.x * 25, y: mousePos.y * 25 });
    gsap.to(plane4.current, { x: mousePos.x * -25, y: mousePos.y * -25 });
    gsap.to(plane5.current, { x: mousePos.x * 30, y: mousePos.y * 40 });
  }, [mousePos]);

  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, staggerChildren: 0.3 },
    },
  };

  return (
    <section className='py-8 gallery -mt-[580px] md:-mt-0'>
      <div className='py-6 font-bold text-white'>
        <h1 className='text-3xl md:text-4xl text-center'>Event <span className='text-[#A0CF3B]'>Gallery</span></h1>
      </div>
      <main>
        <div onMouseMove={(e) => manageMouseMove(e)}
          className="relative w-full min-h-screen overflow-hidden hidden md:flex">
          {/* Plane 1 */}
          <div ref={plane1} className="absolute w-full h-full filter brightness-70">
            <img src={images[0]} alt="img1" className="absolute top-[70%] left-[80%] w-[300px]" />
            <img src={images[1]} alt="img2" className="absolute top-[65%] left-[5%] w-[300px]" />
            <img src={images[6]} alt="img7" className="absolute top-0 left-[30%] w-[400px]" />
          </div>

          {/* Plane 2 */}
          <div ref={plane2} className="absolute w-full h-full filter brightness-70">
            <img src={images[3]} alt="img4" className="absolute top-[10%] left-[2%] w-[300px]" />
            <img src={images[5]} alt="img6" className="absolute top-[1%] left-[65%] w-[250px]" />
            <img src={images[7]} alt="img8" className="absolute top-[62%] left-[60%] w-[325px]" />
          </div>

          {/* Plane 3 */}
          <div ref={plane3} className="absolute w-full h-full filter brightness-70">
            <img src={images[2]} alt="img3" className="absolute top-[2.5%] left-[80%] w-[300px]" />
            <img src={images[4]} alt="img5" className="absolute top-[70%] left-[40%] w-[300px]" />
            <img src={images[9]} alt="img10" className="absolute top-[15%] left-[50%] w-[225px]" />
          </div>

          {/* Plane 4 */}
          <div ref={plane4} className="absolute w-full h-full filter brightness-40">
            <img src={images[8]} alt="img9" className="absolute top-[45%] left-[20%] w-[300px]" />
            <img src={images[11]} alt="img12" className="absolute top-[40%] left-[82%] w-[300px]" />
            <img src={images[12]} alt="img13" className="absolute top-[20%] left-[25%] w-[225px]" />
          </div>

          {/* Plane 5 */}
          <div ref={plane5} className="absolute w-full h-full filter brightness-30">
            <img src={images[10]} alt="img11" className="absolute top-[30%] left-[60%] w-[300px]" />
            <img src={images[13]} alt="img14" className="absolute top-[67%] left-[26%] w-[300px]" />
            <img src={images[14]} alt="img15" className="absolute top-[40%] left-[40%] w-[275px]" />
          </div>
        </div>
        <div className='flex md:hidden'>
          <div className="grid grid-cols-2 gap-2 p-4">
            {images.map((image, index) => (
              <div
                key={index}
                className="relative rounded-lg overflow-hidden bg-black transition-all duration-300 hover:scale-105"
              >
                <img
                  src={image}
                  alt={`Gallery ${index}`}
                  className="w-[200px] h-[200px] object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </main>
    </section>
  );
}

