import React, { useRef } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import { useServicesAnimations } from "../utils/service-animation";

const Services = () => {
  const titleRef = useRef(null);
  const cardsRef = useRef(null);

  useServicesAnimations(titleRef, cardsRef);

  return (
    <div className="py-12 services">
      <div className="max-w-[1240px] mx-auto flex flex-col items-center justify-center px-4">
        <p className="text-3xl md:text-4xl font-semibold text-white  py-2">
          Our <span className='text-[#A0CF3B] '>Services</span>
        </p>
        <h1
          ref={titleRef}
          className="line1 mt-4 text-3xl sm:text-4xl md:text-6xl text-center font-bold text-white"
        >
          Torque is a{" "}
          <span className="bg-clip-text text-transparent bg-gradient-to-br from-[#34e89e] to-[#0f3443]">
            fairy godmother
          </span>{" "}
          -
        </h1>
        <h1
          ref={titleRef}
          className="line2 text-3xl sm:text-4xl md:text-6xl text-center font-bold text-white"
        >
          every event is a happily ever after
        </h1>
      </div>

      {/* Hide horizontal scroll on mobile and display a single column grid */}
      <div className="hidden md:flex">
        <HorizontalScrollCarousel cardsRef={cardsRef} />
      </div>

      {/* Mobile Grid */}
      <div className="md:hidden grid grid-cols-1 gap-4 px-4 mt-6">
        {cards.map((card) => (
          <Card card={card} key={card.id} mobileView />
        ))}
      </div>
    </div>
  );
};

const HorizontalScrollCarousel = ({ cardsRef }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["1%", "-60%"]);

  return (
    <section ref={targetRef} className="relative h-[150vh] sm:h-[200vh]">
      <div className="sticky top-0 flex h-screen items-center overflow-hidden px-4">
        <motion.div ref={cardsRef} style={{ x }} className="flex gap-4 sm:gap-6 md:gap-8">
          {cards.map((card) => {
            return <Card card={card} key={card.id} />;
          })}
        </motion.div>
      </div>
    </section>
  );
};

const Card = ({ card, mobileView }) => {
  return (
    <div
      key={card.id}
      className={`group relative overflow-hidden rounded-xl ${
        mobileView ? "h-[250px] w-full" : "h-[400px] sm:h-[500px] md:h-[550px] w-[250px] sm:w-[300px] md:w-[400px]"
      }`}
    >
      <div
        style={{
          backgroundImage: `url(${card.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100%",
        }}
        className="absolute inset-0 z-0 transition-transform duration-300 group-hover:scale-110"
      ></div>
      <div
        style={{
          background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), ${
            card.background || "#1A1A1A"
          })`,
        }}
        className="absolute inset-0 z-10"
      ></div>
      <div className="absolute inset-0 p-4 flex flex-col justify-between z-20">
        <p className="text-lg sm:text-xl md:text-2xl font-black uppercase text-white">
          {card.title}
        </p>
        <div>
          <p className="text-sm sm:text-lg md:text-xl text-left font-semibold text-white mb-2">
            {card.desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;

const cards = [
  {
    url: "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/services/Corporate.jpg",
    title: "Corporate Event",
    id: 1,
    desc: "We prepare the event, while you get ready for the event.",
    background: "#FF6F61",
  },
  {
    url: "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/gallery/img-3.jpg",
    title: "Live Concerts",
    id: 2,
    desc: "Plan on screaming? It's okay, we'll do the scheming.",
    background: "#6B5B95",
  },
  {
    url: "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/services/Media.jpg",
    title: "Media Events",
    id: 3,
    desc: "Celebrity autographs aren't our priority but celebrity events are.",
    background: "#88B04B",
  },
  {
    url: "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/services/Awards.jpg",
    title: "Award Shows",
    id: 4,
    desc: "And the Award goes too.... Ouch! No Spoilers. Trust us we won't spoil it or the event.",
    background: "#F7CAC9",
  },
  {
    url: "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/services/Medical-conference.jpg",
    title: "Medical Conferences",
    id: 5,
    desc: "Every conference deserves our touch to add more amusement.",
    background: "#92A8D1",
  },
  {
    url: "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/services/Virtual.jpg",
    title: "Virtual events",
    id: 6,
    desc: "Bring us our cape and tiara, we are going to save the show on-line.",
    background: "#955251",
  },
  {
    url: "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/services/Hybrid.jpg",
    title: "Hybrid Events",
    id: 7,
    desc: "We know hybrid events are difficult. But difficulty is our cup of tea.",
    background: "#B565A7",
  },
  {
    url: "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/services/Space.jpg",
    title: "Space Designs",
    id: 8,
    desc: "When brands give us space, we build brand designs.",
    background: "#009B77",
  },
];
